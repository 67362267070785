// NumberGuessingGame.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const NumberGuessingGame = () => {
  const [guess, setGuess] = useState('');
  const [targetNumber] = useState(Math.floor(Math.random() * 100) + 1);
  const [message, setMessage] = useState('');

  const checkGuess = (e) => {
    e.preventDefault(); // 폼 제출 시 기본 동작 방지

    const guessNumber = parseInt(guess);

    if (guessNumber > targetNumber) {
      setMessage('너무 높습니다.');
    } else if (guessNumber < targetNumber) {
      setMessage('너무 낮습니다.');
    } else {
      setMessage('정답입니다!');
    }
  };

  return (
    <div className="content-box number-guessing-game">
      <Helmet>
        <title>숫자 맞추기 게임</title>
        <meta name="description" content="숫자를 맞추는 재미있는 게임입니다." />
        <meta name="keywords" content="숫자 맞추기 게임, 숫자 게임, 퍼즐 게임, 게임, 숫자 찾기, 두뇌 게임, 숫자 기억력 게임, 숫자 비교 게임, 숫자 매칭 게임, 기억력 게임, 숫자 조합 게임, 숫자 암기, 숫자 맞추기 도전, 집중력 게임, 숫자 연습 게임, 두뇌 훈련, 기억력 향상 게임, 숫자 문제 해결, 논리 게임, 퍼즐 도전" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>숫자 맞추기 게임</h2>
      <form onSubmit={checkGuess}>
        <div className="input-group">
          <input
            type="number"
            placeholder="1-100"
            value={guess}
            onChange={(e) => setGuess(e.target.value)}
            min="1"  // 최소값 1
            max="100"  // 최대값 100
            required  // 입력 필수
          />
        </div>
        <button type="submit">확인</button>
      </form>
      {message && <p>{message}</p>}
      <p className="description">이 게임은 1부터 100까지의 숫자 중 하나를 랜덤으로 선택하고, 사용자가 그 숫자를 맞추는 게임입니다.</p>
    </div>
  );
};

export default NumberGuessingGame;
