import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

// 숫자에 쉼표를 추가하는 함수
const formatNumberWithComma = (number) => {
  if (!number) return '';
  return Math.round(number).toLocaleString(); // 세 자리마다 쉼표 추가, 소수점 제거
};

const SalaryCalculator = () => {
  const [annualSalary, setAnnualSalary] = useState('');
  const [monthlySalary, setMonthlySalary] = useState(null);
  const [weeklySalary, setWeeklySalary] = useState(null);
  const [dailySalary, setDailySalary] = useState(null);
  const [hourlySalary, setHourlySalary] = useState(null);

  const calculateSalary = (e) => {
    e.preventDefault(); // 폼 제출 시 기본 동작 방지
    const annual = parseFloat(annualSalary.replace(/,/g, '')); // 쉼표 제거 후 계산

    if (annual >= 0) {
      const monthly = annual / 12;
      const weekly = annual / 52;
      const daily = annual / 260; // 주 5일 근무 기준
      const hourly = daily / 8;   // 하루 8시간 근무 기준

      setMonthlySalary(formatNumberWithComma(monthly));
      setWeeklySalary(formatNumberWithComma(weekly));
      setDailySalary(formatNumberWithComma(daily));
      setHourlySalary(formatNumberWithComma(hourly));
    } else {
      setMonthlySalary(null);
      setWeeklySalary(null);
      setDailySalary(null);
      setHourlySalary(null);
    }
  };

  const resetCalculator = () => {
    setAnnualSalary('');
    setMonthlySalary(null);
    setWeeklySalary(null);
    setDailySalary(null);
    setHourlySalary(null);
  };

  return (
    <div className="content-box salary-calculator">
      <Helmet>
        <title>연봉 계산기</title>
        <meta name="description" content="연봉을 계산할 수 있는 도구입니다." />
        <meta name="keywords" content="연봉 계산기, 연봉 계산, 소득 계산, 월급 계산, 재정 도구, 연봉 추정, 소득 관리, 세후 소득 계산, 연봉 관리, 연봉 비율 계산, 연봉 상승 추적, 연봉 비교, 세금 공제 계산, 연봉 추정 도구, 급여 계산기, 월별 소득 계산, 연봉 증액 분석, 세전 소득 계산, 연봉 분석, 연봉 계산 툴" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>연봉 계산기</h2>
      <form onSubmit={calculateSalary} className="salary-form">
        <div className="input-group">
          <label>연봉 (원)</label>
          <input
            type="text"
            value={formatNumberWithComma(annualSalary)} // 쉼표 형식으로 표시
            onChange={(e) => setAnnualSalary(e.target.value.replace(/[^0-9]/g, ''))} // 숫자만 입력되도록
            placeholder="예: 50,000,000"
            required
          />
        </div>
        <button type="submit">계산하기</button>
        <button type="button" onClick={resetCalculator} className="reset-button">
          초기화
        </button>
      </form>
      {monthlySalary && (
        <div className="result">
          <h3>월급: {monthlySalary} 원</h3>
          <h3>주급: {weeklySalary} 원</h3>
          <h3>일급: {dailySalary} 원</h3>
          <h3>시급: {hourlySalary} 원</h3>
        </div>
      )}
      <p className="description">
        연봉을 기준으로 월급, 주급, 일급, 시급을 계산할 수 있는 도구입니다.<br /><br />
        - 월급: 연봉을 12개월로 나눈 금액.<br />
        - 주급: 연봉을 52주로 나눈 금액.<br />
        - 일급: 주 5일 근무 기준으로 연봉을 260일로 나눈 금액.<br />
        - 시급: 하루 8시간 근무 기준으로 일급을 8시간으로 나눈 금액.<br /><br />

        이를 통해 연봉을 기반으로 실질적인 급여를 확인할 수 있습니다.
      </p>
    </div>
  );
};

export default SalaryCalculator;
