// BMI.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const BMI = () => {
  // 상태 변수 정의
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [bmi, setBmi] = useState(null);
  const [bmiResult, setBmiResult] = useState('');

  // BMI 계산 함수
  const calculateBMI = (e) => {
    e.preventDefault();

    if (height > 0 && weight > 0) {
      const heightInMeters = height / 100;
      const calculatedBmi = weight / (heightInMeters * heightInMeters);
      setBmi(calculatedBmi.toFixed(2));

      // BMI 결과 해석
      if (calculatedBmi < 18.5) {
        setBmiResult('저체중');
      } else if (calculatedBmi < 23) {
        setBmiResult('정상 체중');
      } else if (calculatedBmi < 25) {
        setBmiResult('과체중');
      } else {
        setBmiResult('비만');
      }
    } else {
      setBmi(null);
      setBmiResult('');
    }
  };

  return (
    <div className="content-box bmi-calculator">
      <Helmet>
        <title>BMI 계산기</title>
        <meta name="description" content="BMI(체질량지수)를 계산할 수 있는 도구입니다." />
        <meta name="keywords" content="BMI 계산기, 체질량지수, 건강 관리, 체중, 신체 지수, 건강, 비만도 계산, BMI 측정, 몸무게 지수, 비만도 평가, 건강 상태, 체중 관리, 신체 건강, 체형 분석, 다이어트 관리, 건강 데이터, 체중 비율, 체중 측정 도구, 신체 비만, 건강 지표" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>BMI 계산기</h2>
      <form onSubmit={calculateBMI} className="bmi-form">
        <div className="input-group">
          <label>키 (cm)</label>
          <input
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            placeholder="예: 170"
            required
          />
        </div>
        <div className="input-group">
          <label>몸무게 (kg)</label>
          <input
            type="number"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            placeholder="예: 65"
            required
          />
        </div>
        <button type="submit">계산하기</button>
      </form>
      {bmi && (
        <div className="result">
          <h3>BMI: {bmi}</h3>
          <p>{bmiResult}</p>
        </div>
      )}
      <p className="description">
        이 도구는 사용자의 키와 몸무게를 바탕으로 BMI(체질량 지수)를 계산합니다. BMI는 체지방의 대략적인 지표로 사용되며, 저체중, 정상 체중, 과체중, 비만을 구분하는 데 유용합니다. 결과는 BMI 수치와 함께 해당되는 체중 범주를 제공합니다.
      </p>
    </div>
  );
};

export default BMI;
