// QRCodeGenerator.js
import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { Helmet } from 'react-helmet-async';

const QRCodeGenerator = () => {
  const [text, setText] = useState('');

  const handleGenerate = (e) => {
    e.preventDefault(); // 폼 제출 시 기본 동작 방지
    // QR 코드 생성은 입력된 텍스트를 기반으로 자동으로 이루어집니다.
  };

  return (
    <div className="content-box qr-code-generator">
      <Helmet>
        <title>QR 코드 생성기</title>
        <meta name="description" content="QR 코드를 생성할 수 있는 도구입니다." />
        <meta name="keywords" content="QR 코드 생성기, QR코드, 바코드 생성기, QR코드 생성, URL 코드화, QR 코드 스캔, QR 코드 관리, QR 코드 만들기, QR 코드 생성 도구, QR코드 제작, QR 코드 인식, QR 코드 변환, QR코드 이미지, 바코드 관리, QR코드 커스터마이즈, QR코드 URL, QR 코드 디자인, QR 코드 편집, 바코드 스캔, QR 코드 생성기 무료, QR 코드 제작 도구" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>QR 코드 생성기</h2>
      <form onSubmit={handleGenerate}>
        <div className="input-group">
          <input
            type="text"
            placeholder="QR 코드로 변환할 텍스트"
            value={text}
            onChange={(e) => setText(e.target.value)}
            required  // 입력 필수
          />
        </div>
        <button type="submit">QR 코드 생성</button>
      </form>
      {text && <QRCodeCanvas value={text} />}
      <p className="description">이 도구는 입력한 텍스트나 URL을 QR 코드로 변환해줍니다. QR 코드를 스캔하여 정보를 확인할 수 있습니다.</p>
    </div>
  );
};

export default QRCodeGenerator;
