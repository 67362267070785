import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

// 숫자에 쉼표를 추가하는 함수
const formatNumberWithComma = (number) => {
  if (!number) return '';
  return Math.round(number).toLocaleString(); // 세 자리마다 쉼표 추가, 소수점 제거
};

const TaxCalculator = () => {
  const [income, setIncome] = useState('');
  const [tax, setTax] = useState(null);

  const calculateTax = (e) => {
    e.preventDefault(); // 폼 제출 시 기본 동작 방지
    const taxableIncome = parseFloat(income.replace(/,/g, '')); // 쉼표 제거 후 계산

    if (taxableIncome >= 0) {
      let calculatedTax = 0;

      // 간단한 누진세 세율 적용 예시 (실제 세율과 다를 수 있음)
      if (taxableIncome <= 12000000) {
        calculatedTax = taxableIncome * 0.06;
      } else if (taxableIncome <= 46000000) {
        calculatedTax = 12000000 * 0.06 + (taxableIncome - 12000000) * 0.15;
      } else if (taxableIncome <= 88000000) {
        calculatedTax = 12000000 * 0.06 + 34000000 * 0.15 + (taxableIncome - 46000000) * 0.24;
      } else if (taxableIncome <= 150000000) {
        calculatedTax = 12000000 * 0.06 + 34000000 * 0.15 + 42000000 * 0.24 + (taxableIncome - 88000000) * 0.35;
      } else {
        calculatedTax = 12000000 * 0.06 + 34000000 * 0.15 + 42000000 * 0.24 + 62000000 * 0.35 + (taxableIncome - 150000000) * 0.38;
      }

      setTax(formatNumberWithComma(calculatedTax)); // 쉼표 추가하여 표시
    } else {
      setTax(null);
    }
  };

  const resetCalculator = () => {
    setIncome('');
    setTax(null);
  };

  return (
    <div className="content-box tax-calculator">
      <Helmet>
        <title>세금 계산기</title>
        <meta name="description" content="세금을 계산할 수 있는 도구입니다." />
        <meta name="keywords" content="세금 계산기, 세금, 소득세, 세율 계산, 재정 도구, 세금 추정, 세금 공제, 세금 관리, 세금 부담, 세금 추정 도구, 세금 계산 도구, 세율 비교, 소득세 계산기, 세금 비율 계산, 재정 계획, 세금 공제 계산기, 세금 계산 공식, 세금 분석, 세율 관리, 세금 절감" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>세금 계산기</h2>
      <form onSubmit={calculateTax} className="tax-form">
        <div className="input-group">
          <label>연 소득 금액 (원)</label>
          <input
            type="text"
            value={formatNumberWithComma(income)}  // 쉼표 형식으로 표시
            onChange={(e) => setIncome(e.target.value.replace(/[^0-9]/g, ''))}  // 숫자만 입력되도록
            placeholder="예: 50,000,000"
            required
          />
        </div>
        <button type="submit">계산하기</button>
        <button type="button" onClick={resetCalculator} className="reset-button">
          초기화
        </button>
      </form>
      {tax && (
        <div className="result">
          <h3>예상 세금: {tax} 원</h3>
        </div>
      )}
      <p className="description">
        이 세금 계산기는 연 소득에 따라 예상되는 소득세를 계산해줍니다. 누진세 방식에 따라
        수입에 비례하여 세율이 다르게 적용됩니다.<br /><br />

        - 연 소득 12,000,000원 이하: 6% 적용.<br />
        - 연 소득 12,000,000 ~ 46,000,000원: 15% 적용.<br />
        - 연 소득 46,000,000 ~ 88,000,000원: 24% 적용.<br />
        - 연 소득 88,000,000 ~ 150,000,000원: 35% 적용.<br />
        - 연 소득 150,000,000원 초과: 38% 적용.<br /><br />

        실제 세율은 나라별로 다를 수 있습니다.
      </p>
    </div>
  );
};

export default TaxCalculator;
