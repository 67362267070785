import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const BaseConverter = () => {
  const [number, setNumber] = useState('');
  const [inputBase, setInputBase] = useState('10');  // 입력 진법
  const [outputBase, setOutputBase] = useState('10'); // 출력 진법
  const [result, setResult] = useState('');

  // 각 진법에 맞는 유효성 검사 패턴
  const basePatterns = {
    '2': /^[01]+$/,           // 2진수: 0과 1만 허용
    '8': /^[0-7]+$/,           // 8진수: 0부터 7까지 허용
    '10': /^[0-9]+$/,          // 10진수: 0부터 9까지 허용
    '16': /^[0-9a-fA-F]+$/,    // 16진수: 0-9, a-f, A-F 허용
  };

  const baseOptions = [
    { value: '2', label: '2진수' },
    { value: '8', label: '8진수' },
    { value: '10', label: '10진수' },
    { value: '16', label: '16진수' },
  ];

  const handleNumberChange = (e) => {
    const value = e.target.value;
    const pattern = basePatterns[inputBase];

    // 입력된 값이 해당 진법의 패턴과 일치하는지 확인
    if (value === '' || pattern.test(value)) {
      setNumber(value);
      convertNumber(value, inputBase, outputBase);
    }
  };

  const handleInputBaseChange = (e) => {
    setInputBase(e.target.value);
    setNumber(''); // 진법이 바뀔 때 입력 초기화
    setResult(''); // 결과도 초기화

    // 입력 진법과 동일한 출력 진법이 선택된 경우 출력 진법을 기본값으로 변경
    if (e.target.value === outputBase) {
      const newOutputBase = baseOptions.find(option => option.value !== e.target.value).value;
      setOutputBase(newOutputBase);
    }
  };

  const handleOutputBaseChange = (e) => {
    setOutputBase(e.target.value);
    convertNumber(number, inputBase, e.target.value);
  };

  // 문자열을 20자마다 줄바꿈 추가하는 함수
  const formatResult = (str) => {
    return str.replace(/(.{20})/g, '$1\n'); // 20자마다 줄바꿈 추가
  };

  const convertNumber = (num, inputBase, outputBase) => {
    if (!num) {
      setResult('');
      return;
    }

    try {
      // 입력값을 지정된 진법으로 파싱
      const decimalNumber = parseInt(num, parseInt(inputBase));

      // 파싱한 결과를 출력 진법으로 변환
      let convertedResult = '';
      switch (outputBase) {
        case '2':
          convertedResult = decimalNumber.toString(2);
          break;
        case '8':
          convertedResult = decimalNumber.toString(8);
          break;
        case '10':
          convertedResult = decimalNumber.toString(10);
          break;
        case '16':
          convertedResult = decimalNumber.toString(16).toUpperCase();
          break;
        default:
          setResult('잘못된 출력 진법 선택입니다');
          return;
      }

      setResult(formatResult(convertedResult));  // 20자마다 줄바꿈 적용
    } catch (error) {
      setResult('변환 오류가 발생했습니다');
    }
  };

  // 입력 진법과 동일한 출력 진법을 제외한 필터링된 출력 옵션
  const filteredOutputOptions = baseOptions.filter(option => option.value !== inputBase);

  return (
    <div className="content-box base-converter">
      <Helmet>
        <title>숫자 베이스 변환기</title>
        <meta name="description" content="숫자를 다양한 진법으로 변환하는 도구입니다. 2진수, 8진수, 10진수, 16진수 간 변환을 지원합니다." />
        <meta name="keywords" content="숫자 변환기, 베이스 변환기, 진법 변환, 2진수, 8진수, 10진수, 16진수, 프로그래밍 도구, 개발 도구, 수학 도구, 계산기, 진법 계산, 숫자 계산기" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>숫자 베이스 변환기</h2>
      <form className="base-form">
        <div className="input-section">
          <label>입력 진법</label>
          <select value={inputBase} onChange={handleInputBaseChange} className="base-select">
            {baseOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <input
            type="text"
            placeholder="숫자를 입력하세요"
            value={number}
            onChange={handleNumberChange}
            className="base-input"
          />

          <label>출력 진법</label>
          <select value={outputBase} onChange={handleOutputBaseChange} className="base-select">
            {filteredOutputOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </form>
      {result && (
        <div className="base-converter-result" style={{ whiteSpace: 'pre-wrap' }}>
          <h3>결과:</h3>
          <pre>{result}</pre> {/* 줄바꿈을 반영한 결과 */}
        </div>
      )}
      <p className="description">
          이 도구는 사용자가 입력한 숫자를 다양한 진법(2진수, 8진수, 10진수, 16진수)으로 변환할 수 있도록 도와줍니다. 각 진법에서 입력 가능한 숫자는 다음과 같습니다:
          <br />
          <br />
          1. **2진수 (Binary)**: 2진수는 0과 1 두 개의 숫자로 이루어집니다. 주로 컴퓨터에서 데이터를 처리하는 기본 단위로 사용됩니다.
          <br />
          2. **8진수 (Octal)**: 8진수는 0부터 7까지의 숫자를 사용합니다. 8진수는 과거의 컴퓨터 시스템에서 널리 사용되었으며, 2진수보다 더 간결하게 표현할 수 있습니다.
          <br />
          3. **10진수 (Decimal)**: 우리가 일상적으로 사용하는 숫자 체계로, 0부터 9까지의 숫자를 사용합니다. 이는 대부분의 계산과 숫자 표현에서 기본적으로 사용됩니다.
          <br />
          4. **16진수 (Hexadecimal)**: 16진수는 0부터 9까지의 숫자와 A부터 F까지의 알파벳(대소문자 모두 가능)을 사용합니다. 컴퓨터 시스템에서 메모리 주소나 색상 표현 등에서 많이 사용됩니다.
          <br />
          <br />
          입력한 숫자는 선택한 진법에 따라 검증되며, 유효하지 않은 숫자는 입력할 수 없습니다. 예를 들어, 2진수에서는 0과 1만 입력 가능하며, 8진수에서는 0에서 7까지만 입력할 수 있습니다. 변환된 결과는 선택한 출력 진법에 맞춰 화면에 표시됩니다.
          <br />
          이 도구는 컴퓨터 과학, 프로그래밍, 수학 등에서 숫자의 진법을 변환할 때 매우 유용합니다.
      </p>
    </div>
  );
};

export default BaseConverter;
