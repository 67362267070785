// Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  FaBirthdayCake, FaRandom, FaWeight, FaCalendarAlt, FaWallet, FaRulerCombined, FaStopwatch, FaListUl,
  FaQrcode, FaStickyNote, FaPalette, FaGamepad, FaCalculator, FaHeartbeat, FaHome, FaChartLine,
  FaTags, FaDollarSign, FaPiggyBank, FaReceipt, FaChartBar, FaGraduationCap, FaBrain, FaPalette as FaColorMatch, FaTimes
} from 'react-icons/fa'; // 아이콘 임포트

const menuItems = [
  {
    name: '만나이 계산기',
    path: '/age-calculator',
    icon: <FaBirthdayCake className="menu-icon" />,
  },
  {
    name: '로또 번호 추천',
    path: '/lotto-generator',
    icon: <FaRandom className="menu-icon" />,
  },
  {
    name: 'BMI 계산기',
    path: '/bmi',
    icon: <FaWeight className="menu-icon" />,
  },
  {
    name: '날짜 카운터',
    path: '/date-counter',
    icon: <FaCalendarAlt className="menu-icon" />,
  },
  {
    name: '단위 변환기',
    path: '/unit-converter',
    icon: <FaRulerCombined className="menu-icon" />,
  },
  {
    name: '타이머',
    path: '/timer',
    icon: <FaStopwatch className="menu-icon" />,
  },
  {
    name: 'To-do 리스트',
    path: '/todo',
    icon: <FaListUl className="menu-icon" />,
  },
  {
    name: 'QR 코드 생성기',
    path: '/qrcode-generator',
    icon: <FaQrcode className="menu-icon" />,
  },
  {
    name: '간단한 메모장',
    path: '/memo',
    icon: <FaStickyNote className="menu-icon" />,
  },
  {
    name: '색상 코드 변환기',
    path: '/color-converter',
    icon: <FaPalette className="menu-icon" />,
  },
  {
    name: '숫자 맞추기 게임',
    path: '/number-guessing-game',
    icon: <FaGamepad className="menu-icon" />,
  },
  {
    name: '대출 계산기',
    path: '/loan-calculator',
    icon: <FaCalculator className="menu-icon" />,
  },
  {
    name: '모기지 계산기',
    path: '/mortgage-calculator',
    icon: <FaHome className="menu-icon" />,
  },
  {
    name: '복리 계산기',
    path: '/compound-interest-calculator',
    icon: <FaChartLine className="menu-icon" />,
  },
  {
    name: '할인 계산기',
    path: '/discount-calculator',
    icon: <FaTags className="menu-icon" />,
  },
  {
    name: '연봉 계산기',
    path: '/salary-calculator',
    icon: <FaDollarSign className="menu-icon" />,
  },
  {
    name: '퇴직 연금 계산기',
    path: '/retirement-calculator',
    icon: <FaPiggyBank className="menu-icon" />,
  },
  {
    name: '세금 계산기',
    path: '/tax-calculator',
    icon: <FaReceipt className="menu-icon" />,
  },
  {
    name: '주식 수익률 계산기',
    path: '/stock-return-calculator',
    icon: <FaChartBar className="menu-icon" />,
  },
  {
    name: '체지방률 계산기',
    path: '/body-fat-calculator',
    icon: <FaHeartbeat className="menu-icon" />,
  },
  {
    name: '학점 계산기',
    path: '/gpa-calculator',
    icon: <FaGraduationCap className="menu-icon" />,
  },
  {
    name: '출산 예정일 계산기',
    path: '/due-date-calculator',
    icon: <FaCalendarAlt className="menu-icon" />,
  },
  {
    name: '할부 이자 계산기',
    path: '/installment-calculator',
    icon: <FaCalculator className="menu-icon" />,
  },
  {
    name: '지출 관리기',
    path: '/expense-tracker',
    icon: <FaWallet className="menu-icon" />,
  },
  {
    name: '반응 속도 테스트',
    path: '/reaction-time-test',
    icon: <FaBrain className="menu-icon" />, // 추가 아이콘
  },
  {
    name: '색깔 맞추기 게임',
    path: '/color-matching-game',
    icon: <FaColorMatch className="menu-icon" />, // 추가 아이콘
  },
  {
    name: '틱택토 게임',
    path: '/tic-tac-toe',
    icon: <FaTimes className="menu-icon" />, // 추가 아이콘
  },
  {
    name: '음력-양력 변환기',
    path: '/lunar-solar-converter',
    icon: <FaCalendarAlt className="menu-icon" />
   },
  {
    name: '숫자 베이스 변환기',
    path: '/base-converter',
    icon: <FaCalculator className="menu-icon" />,
  },
];

const Home = () => {
  return (
    <div className="home-container">
      <Helmet>
        <title>SmartTools365 - 일상에 필요한 모든 도구, 한 곳에서!</title>
        <meta name="keywords" content="도구 모음, 게임 모음, 계산기, 만나이 계산기, BMI 계산기, 대출 계산기, 로또 번호 생성기, 일정 관리, 게임, 반응속도 테스트, 틱택토, 색 맞추기 게임, 색상 게임, 한국 나이 계산기, 음력 양력 변환기, 달력 변환기, 숫자 맞추기 게임, 날씨 계산기, 스마트 도구, 퀵 계산기, 일상 도구 모음" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <div className="menu-grid">
        {menuItems.map((item, index) => (
          <Link to={item.path} className="menu-item" key={index}>
            <div className="menu-content">
              {item.icon}
              <h2>{item.name}</h2>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Home;
