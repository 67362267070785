// Timer.js
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const Timer = () => {
  const [seconds, setSeconds] = useState('');
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    if (timeLeft === 0) {
      alert('시간이 종료되었습니다!');
    }
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [timeLeft]);

  const startTimer = (e) => {
    e.preventDefault(); // 폼 제출 시 기본 동작 방지
    if (seconds > 0) {
      setTimeLeft(parseInt(seconds));
    }
  };

  return (
    <div className="content-box timer">
      <Helmet>
        <title>타이머</title>
        <meta name="description" content="타이머 기능을 제공하는 도구입니다." />
        <meta name="keywords" content="타이머, 시간 측정기, 카운트다운, 스톱워치, 시간 관리, 알람 설정, 카운트다운 타이머, 시간 기록, 초시계, 알람 도구, 시간 추적, 일정 관리 도구, 남은 시간 추적, 시간 분석, 시간 맞추기, 시간 절약 도구, 경과 시간 계산, 알람 타이머, 목표 시간 설정, 일정 알람" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>타이머</h2>
      <form onSubmit={startTimer}>
        <div className="input-group">
          <input
            type="number"
            placeholder="초 입력"
            value={seconds}
            onChange={(e) => setSeconds(e.target.value)}
            required  // 입력 필수
          />
        </div>
        <button type="submit">타이머 시작</button>
      </form>
      {timeLeft !== null && <p>남은 시간: {timeLeft}초</p>}
      <p className="description">이 타이머는 설정한 시간이 끝나면 알림을 제공해줍니다.</p>
    </div>
  );
};

export default Timer;
