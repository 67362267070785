import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

const LunarSolarConverter = () => {
  const [solarDate, setSolarDate] = useState('');
  const [lunarDate, setLunarDate] = useState('');
  const [result, setResult] = useState('');

  const apiKey = 'qs+PvPIANusAwzE4CYnCyH8bTjv57uNQje3H2rFqT83eAgNK2cM0jOtuO7bKPiSlq1iOzBxB9qx+vEIO8bZGEA==';  // 공공데이터포털에서 발급받은 API 키

  const convertSolarToLunar = async () => {
    if (!solarDate) return;
    const [year, month, day] = solarDate.split('-').map(Number);

    try {
      const response = await axios.get('https://apis.data.go.kr/B090041/openapi/service/LrsrCldInfoService/getLunCalInfo', {
        params: {
          solYear: year,
          solMonth: String(month).padStart(2, '0'),
          solDay: String(day).padStart(2, '0'),
          ServiceKey: apiKey,
          numOfRows: 10,
          pageNo: 1,
        }
      });

      // 전체 응답을 확인하여 디버깅에 도움
      console.log(response.data);

      const data = response.data.response.body.items.item;
      if (data) {
        const lunarYear = data.lunYear;
        const lunarMonth = data.lunMonth;
        const lunarDay = data.lunDay;
        const lunarLeap = data.lunLeapmonth === '윤' ? '(윤달)' : '';

        setResult(`양력 ${solarDate}는 음력으로 ${lunarYear}-${lunarMonth}-${lunarDay} ${lunarLeap} 입니다.`);
      } else {
        setResult("변환할 수 있는 데이터가 없습니다.");
      }
    } catch (error) {
      // 에러를 콘솔에 출력하여 어떤 오류가 발생했는지 확인
      console.error("API 요청 오류:", error);
      setResult("음력 변환 중 오류가 발생했습니다.");
    }
  };

  // 음력을 양력으로 변환하는 함수 추가
  const convertLunarToSolar = async () => {
    if (!lunarDate) return;
    const [year, month, day] = lunarDate.split('-').map(Number);

    try {
      const response = await axios.get('https://apis.data.go.kr/B090041/openapi/service/LrsrCldInfoService/getSolCalInfo', {
        params: {
          lunYear: year,
          lunMonth: String(month).padStart(2, '0'),
          lunDay: String(day).padStart(2, '0'),
          ServiceKey: apiKey,
          numOfRows: 10,
          pageNo: 1,
        }
      });

      // 전체 응답을 확인하여 디버깅에 도움
      console.log(response.data);

      const data = response.data.response.body.items.item;
      if (data) {
        const solarYear = data.solYear;
        const solarMonth = data.solMonth;
        const solarDay = data.solDay;

        setResult(`음력 ${lunarDate}는 양력으로 ${solarYear}-${solarMonth}-${solarDay} 입니다.`);
      } else {
        setResult("변환할 수 있는 데이터가 없습니다.");
      }
    } catch (error) {
      // 에러를 콘솔에 출력하여 어떤 오류가 발생했는지 확인
      console.error("API 요청 오류:", error);
      setResult("양력 변환 중 오류가 발생했습니다.");
    }
  };

  return (
    <div className="content-box lunar-solar-converter">
      <Helmet>
        <title>음력-양력 변환기</title>
        <meta name="description" content="음력에서 양력, 양력에서 음력 변환기를 사용해 날짜를 쉽게 변환하세요." />
        <meta name="keywords" content="음력, 양력, 음력 양력 변환, 날짜 변환기, 달력 변환, 양력 변환기, 음력 계산기, 날짜 계산기, 음력 변환 도구, 한국식 달력 변환, 음력 변환 방법, 음력 양력 차이, 음력 양력 변환 공식, 달력 계산기, 음력 날짜 추적, 음력 양력 계산, 달력 변환 공식, 음력 양력 계산 도구, 음력 추적, 음력 변환 방법" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>음력-양력 변환기</h2>
      <div className="input-group">
        <label>양력 입력 (예: 2023-05-15)</label>
        <input
          type="date"
          value={solarDate}
          onChange={(e) => setSolarDate(e.target.value)}
          placeholder="양력 입력"
        />
        <button onClick={convertSolarToLunar}>음력으로 변환</button>
      </div>

      <div className="input-group">
        <label>음력 입력 (예: 2023-05-15)</label>
        <input
          type="date"
          value={lunarDate}
          onChange={(e) => setLunarDate(e.target.value)}
          placeholder="음력 입력"
        />
        <button onClick={convertLunarToSolar}>양력으로 변환</button>
      </div>

      {result && (
        <div className="result">
          <h3>변환 결과</h3>
          <p>{result}</p>
        </div>
      )}
      <p className="description">
        이 도구는 양력 날짜를 음력 날짜로, 또는 음력 날짜를 양력 날짜로 변환해주는 유용한 도구입니다. 간편하게 날짜 변환을 할 수 있습니다.
      </p>
    </div>
  );
};

export default LunarSolarConverter;
