import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import AgeCalculator from './pages/AgeCalculator';
import LottoGenerator from './pages/LottoGenerator';
import BMI from './pages/BMI';
import DateCounter from './pages/DateCounter';
import UnitConverter from './pages/UnitConverter';
import Timer from './pages/Timer';
import Todo from './pages/Todo';
import QRCodeGenerator from './pages/QRCodeGenerator';
import Memo from './pages/Memo';
import ColorConverter from './pages/ColorConverter';
import NumberGuessingGame from './pages/NumberGuessingGame';
import LoanCalculator from './pages/LoanCalculator';
import CompoundInterestCalculator from './pages/CompoundInterestCalculator';
import RetirementCalculator from './pages/RetirementCalculator';
import TaxCalculator from './pages/TaxCalculator';
import DiscountCalculator from './pages/DiscountCalculator';
import SalaryCalculator from './pages/SalaryCalculator';
import MortgageCalculator from './pages/MortgageCalculator';
import GPACalculator from './pages/GPACalculator';
import StockReturnCalculator from './pages/StockReturnCalculator';
import BodyFatCalculator from './pages/BodyFatCalculator';
import DueDateCalculator from './pages/DueDateCalculator';
import InstallmentCalculator from './pages/InstallmentCalculator';
import ExpenseTracker from './pages/ExpenseTracker';
import ReactionTimeTest from './pages/ReactionTimeTest'; // 추가
import ColorMatchingGame from './pages/ColorMatchingGame'; // 추가
import TicTacToe from './pages/TicTacToe'; // 추가
import LunarSolarConverter from './pages/LunarSolarConverter';
import BaseConverter from './pages/BaseConverter';
import './App.css';

const Header = ({ toggleMenu }) => {
  const location = useLocation();

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/age-calculator':
        return '만나이 계산기';
      case '/lotto-generator':
        return '로또 번호 추천';
      case '/bmi':
        return 'BMI 계산기';
      case '/date-counter':
        return '날짜 카운터';
      case '/unit-converter':
        return '단위 변환기';
      case '/timer':
        return '타이머';
      case '/todo':
        return 'To-do 리스트';
      case '/qrcode-generator':
        return 'QR 코드 생성기';
      case '/memo':
        return '간단한 메모장';
      case '/color-converter':
        return '색상 코드 변환기';
      case '/number-guessing-game':
        return '숫자 맞추기 게임';
      case '/loan-calculator':
        return '대출 계산기';
      case '/compound-interest-calculator':
        return '복리 계산기';
      case '/retirement-calculator':
        return '퇴직 연금 계산기';
      case '/tax-calculator':
        return '세금 계산기';
      case '/discount-calculator':
        return '할인 계산기';
      case '/salary-calculator':
        return '연봉 계산기';
      case '/mortgage-calculator':
        return '모기지 계산기';
      case '/gpa-calculator':
        return '학점 계산기';
      case '/stock-return-calculator':
        return '주식 수익률 계산기';
      case '/body-fat-calculator':
        return '체지방률 계산기';
      case '/due-date-calculator':
        return '출산 예정일 계산기';
      case '/installment-calculator':
        return '할부 이자 계산기';
      case '/expense-tracker':
        return '지출 관리기';
      case '/reaction-time-test':
        return '반응 속도 테스트';
      case '/color-matching-game':
        return '색깔 맞추기 게임';
      case '/tic-tac-toe':
        return '틱택토 게임';
      case '/lunar-solar-converter':
        return '음력-양력 변환기';
      case '/base-converter':
        return '숫자 베이스 변환기';
      default:
        return '메인-Home';
    }
  };

  return (
    <header className="header">
      <button className="menu-button" onClick={toggleMenu}>
        &#9776;
      </button>
      <h1 className="page-title">{getPageTitle()}</h1>
    </header>
  );
};

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [openCalculator, setOpenCalculator] = useState(false); // 상태 추가
  const [openTools, setOpenTools] = useState(false); // 상태 추가
  const [openGames, setOpenGames] = useState(false); // 상태 추가

  // 메뉴를 열고 닫는 함수 정의
  const toggleMenu = () => {
    setIsOpen(!isOpen); // isOpen 값을 토글
  };

  // 대메뉴 토글 함수
  const toggleCalculator = () => {
    setOpenCalculator(!openCalculator);
  };

  const toggleTools = () => {
    setOpenTools(!openTools);
  };

  const toggleGames = () => {
    setOpenGames(!openGames);
  };

  return (
    <Router>
      <div className="App">
        <Header toggleMenu={toggleMenu} isOpen={isOpen} />  {/* toggleMenu 함수 전달 */}

        <nav className={`side-menu ${isOpen ? 'open' : ''}`}>
          <ul>
            <li>
              <Link to="/" onClick={toggleMenu}>메인-Home</Link> {/* toggleMenu 함수 호출 */}
            </li>

            {/* 계산기 대메뉴 */}
            <li className="menu-category" onClick={toggleCalculator}>
              계산기 {openCalculator ? '▲' : '▼'}
            </li>
            {openCalculator && (
              <>
                <li><Link to="/age-calculator" onClick={toggleMenu}>만나이 계산기</Link></li>
                <li><Link to="/bmi" onClick={toggleMenu}>BMI 계산기</Link></li>
                <li><Link to="/loan-calculator" onClick={toggleMenu}>대출 계산기</Link></li>
                <li><Link to="/compound-interest-calculator" onClick={toggleMenu}>복리 계산기</Link></li>
                <li><Link to="/retirement-calculator" onClick={toggleMenu}>퇴직 연금 계산기</Link></li>
                <li><Link to="/tax-calculator" onClick={toggleMenu}>세금 계산기</Link></li>
                <li><Link to="/discount-calculator" onClick={toggleMenu}>할인 계산기</Link></li>
                <li><Link to="/salary-calculator" onClick={toggleMenu}>연봉 계산기</Link></li>
                <li><Link to="/mortgage-calculator" onClick={toggleMenu}>모기지 계산기</Link></li>
                <li><Link to="/gpa-calculator" onClick={toggleMenu}>학점 계산기</Link></li>
                <li><Link to="/stock-return-calculator" onClick={toggleMenu}>주식 수익률 계산기</Link></li>
                <li><Link to="/body-fat-calculator" onClick={toggleMenu}>체지방률 계산기</Link></li>
                <li><Link to="/due-date-calculator" onClick={toggleMenu}>출산 예정일 계산기</Link></li>
                <li><Link to="/installment-calculator" onClick={toggleMenu}>할부 이자 계산기</Link></li>
              </>
            )}

            {/* 도구 대메뉴 */}
            <li className="menu-category" onClick={toggleTools}>
              도구 {openTools ? '▲' : '▼'}
            </li>
            {openTools && (
              <>
                <li><Link to="/expense-tracker" onClick={toggleMenu}>지출 관리기</Link></li>
                <li><Link to="/lunar-solar-converter" onClick={toggleMenu}>음력-양력 변환기</Link></li>
                <li><Link to="/unit-converter" onClick={toggleMenu}>단위 변환기</Link></li>
                <li><Link to="/base-converter" onClick={toggleMenu}>숫자 베이스 변환기</Link></li>
                <li><Link to="/date-counter" onClick={toggleMenu}>날짜 카운터</Link></li>
                <li><Link to="/todo" onClick={toggleMenu}>To-do 리스트</Link></li>
                <li><Link to="/memo" onClick={toggleMenu}>메모장</Link></li>
                <li><Link to="/timer" onClick={toggleMenu}>타이머</Link></li>
                <li><Link to="/qrcode-generator" onClick={toggleMenu}>QR 코드 생성기</Link></li>
                <li><Link to="/color-converter" onClick={toggleMenu}>색상 코드 변환기</Link></li>
              </>
            )}

            {/* 게임 대메뉴 */}
            <li className="menu-category" onClick={toggleGames}>
              게임 {openGames ? '▲' : '▼'}
            </li>
            {openGames && (
              <>
                <li><Link to="/number-guessing-game" onClick={toggleMenu}>숫자 맞추기 게임</Link></li>
                <li><Link to="/lotto-generator" onClick={toggleMenu}>로또 번호 추천</Link></li>
                <li><Link to="/reaction-time-test" onClick={toggleMenu}>반응 속도 테스트</Link></li>
                <li><Link to="/color-matching-game" onClick={toggleMenu}>색깔 맞추기 게임</Link></li>
                <li><Link to="/tic-tac-toe" onClick={toggleMenu}>틱택토 게임</Link></li>
              </>
            )}
          </ul>
        </nav>

        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/age-calculator" element={<AgeCalculator />} />
            <Route path="/bmi" element={<BMI />} />
            <Route path="/unit-converter" element={<UnitConverter />} />
            <Route path="/date-counter" element={<DateCounter />} />
            <Route path="/loan-calculator" element={<LoanCalculator />} />
            <Route path="/compound-interest-calculator" element={<CompoundInterestCalculator />} />
            <Route path="/retirement-calculator" element={<RetirementCalculator />} />
            <Route path="/tax-calculator" element={<TaxCalculator />} />
            <Route path="/discount-calculator" element={<DiscountCalculator />} />
            <Route path="/salary-calculator" element={<SalaryCalculator />} />
            <Route path="/mortgage-calculator" element={<MortgageCalculator />} />
            <Route path="/gpa-calculator" element={<GPACalculator />} />
            <Route path="/stock-return-calculator" element={<StockReturnCalculator />} />
            <Route path="/body-fat-calculator" element={<BodyFatCalculator />} />
            <Route path="/todo" element={<Todo />} />
            <Route path="/memo" element={<Memo />} />
            <Route path="/timer" element={<Timer />} />
            <Route path="/qrcode-generator" element={<QRCodeGenerator />} />
            <Route path="/color-converter" element={<ColorConverter />} />
            <Route path="/number-guessing-game" element={<NumberGuessingGame />} />
            <Route path="/lotto-generator" element={<LottoGenerator />} />
            <Route path="/due-date-calculator" element={<DueDateCalculator />} />
            <Route path="/installment-calculator" element={<InstallmentCalculator />} />
            <Route path="/expense-tracker" element={<ExpenseTracker />} />
            <Route path="/reaction-time-test" element={<ReactionTimeTest />} />
            <Route path="/color-matching-game" element={<ColorMatchingGame />} />
            <Route path="/tic-tac-toe" element={<TicTacToe />} />
            <Route path="/lunar-solar-converter" element={<LunarSolarConverter />} />
            <Route path="/base-converter" element={<BaseConverter />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;

