import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

// 숫자에 쉼표를 추가하는 함수
const formatNumberWithComma = (number) => {
  if (!number) return '';
  return Math.round(number).toLocaleString(); // 세 자리마다 쉼표 추가, 소수점 제거
};

const DiscountCalculator = () => {
  const [originalPrice, setOriginalPrice] = useState('');
  const [discountRate, setDiscountRate] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(null);

  const calculateDiscount = (e) => {
    e.preventDefault();

    const price = parseFloat(originalPrice.replace(/,/g, '')); // 쉼표 제거 후 계산
    const rate = parseFloat(discountRate);

    const discountAmount = price * (rate / 100);
    const finalPrice = price - discountAmount;
    setDiscountedPrice(formatNumberWithComma(finalPrice)); // 쉼표 추가하여 표시
  };

  const resetCalculator = () => {
    setOriginalPrice('');
    setDiscountRate('');
    setDiscountedPrice(null);
  };

  return (
    <div className="content-box discount-calculator">
      <Helmet>
        <title>할인 계산기</title>
        <meta name="description" content="할인 금액을 계산할 수 있는 도구입니다." />
        <meta name="keywords" content="할인 계산기, 할인율 계산, 할인 금액, 가격 할인, 쇼핑 도구, 할인 혜택, 가격 계산기, 쇼핑할인 계산, 할인 적용 계산기, 최종 금액 계산, 할인 퍼센트, 세일 금액, 쇼핑 계산, 할인율 비교, 할인 가격 찾기, 할인 가격 분석, 온라인 쇼핑 할인, 가격 비교 도구, 쇼핑 도구 추천, 할인 금액 계산기" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>할인 계산기</h2>
      <form onSubmit={calculateDiscount} className="discount-form">
        <div className="input-group">
          <label>원래 가격 (원)</label>
          <input
            type="text"
            value={formatNumberWithComma(originalPrice)}  // 쉼표 형식으로 표시
            onChange={(e) => setOriginalPrice(e.target.value.replace(/[^0-9]/g, ''))}  // 숫자만 입력되도록
            placeholder="예: 50,000"
            required
          />
        </div>
        <div className="input-group">
          <label>할인율 (%)</label>
          <input
            type="number"
            step="0.01"
            value={discountRate}
            onChange={(e) => setDiscountRate(e.target.value)}
            placeholder="예: 20"
            required
          />
        </div>
        <button type="submit">계산하기</button>
        <button type="button" onClick={resetCalculator} className="reset-button">
          초기화
        </button>
      </form>
      {discountedPrice && (
        <div className="result">
          <h3>할인된 가격: {discountedPrice} 원</h3>
        </div>
      )}
      <p className="description">
        이 할인 계산기는 원래 가격과 할인율을 입력하여 최종 할인된 금액을 계산해줍니다.<br /><br />

        - 원래 가격: 상품의 원래 가격.<br />
        - 할인율: 상품에 적용되는 할인 비율(%)을 입력합니다.<br /><br />

        계산된 할인 금액은 최종 금액에 반영되어 표시되며, 다양한 할인율에 따라 금액을 쉽게 계산할 수 있습니다.
      </p>
    </div>
  );
};

export default DiscountCalculator;
