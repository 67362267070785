// AgeCalculator.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const AgeCalculator = () => {
  const [birthDate, setBirthDate] = useState({
    year: '',
    month: '',
    day: ''
  });
  const [age, setAge] = useState(null);
  const [koreanAge, setKoreanAge] = useState(null);

  const currentYear = new Date().getFullYear();

  const generateYearOptions = () => {
    const years = [];
    for (let i = currentYear; i >= 1900; i--) {
      years.push(i);
    }
    return years;
  };

  const generateDayOptions = () => {
    const days = [];
    for (let i = 1; i <= 31; i++) {
      days.push(i);
    }
    return days;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBirthDate((prevDate) => ({
      ...prevDate,
      [name]: value,
    }));
  };

  const handleCalculate = (e) => {
    e.preventDefault();
    const { year, month, day } = birthDate;

    const calculatedAge = calculateAge(year, month, day);
    const calculatedKoreanAge = calculateKoreanAge(year);

    setAge(calculatedAge);
    setKoreanAge(calculatedKoreanAge);
  };

  const calculateAge = (birthYear, birthMonth, birthDay) => {
    const today = new Date();
    const birthDate = new Date(birthYear, birthMonth - 1, birthDay);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    return age;
  };

  const calculateKoreanAge = (birthYear) => {
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear + 1; // 한국 나이 계산 방식
  };

  return (
    <div className="content-box age-calculator">
      <Helmet>
        <title>만나이 계산기</title>
        <meta name="description" content="정확한 만나이를 계산할 수 있는 도구입니다." />
        <meta name="keywords" content="만나이 계산기, 나이 계산기, 만나이, 생일 계산기, 한국식 나이 계산, 국제 나이, 연령 계산, 생년월일 계산기, 나이 차이 계산, 나이 변환기, 한국식 만나이, 태어난 연도 계산기, 나이 카운트, 나이 측정, 정확한 나이 계산, 나이 계산 방법, 나이 계산 툴, 연도별 나이, 날짜별 나이, 나이 계산 포털" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h1 className="page-title">만나이 계산기 & 한국 나이 계산기</h1>
      <form onSubmit={handleCalculate}>
        <div className="input-group">
          <select name="year" value={birthDate.year} onChange={handleInputChange} required>
            <option value="">태어난 연도</option>
            {generateYearOptions().map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>

          <select name="month" value={birthDate.month} onChange={handleInputChange} required>
            <option value="">태어난 월</option>
            {[...Array(12).keys()].map((month) => (
              <option key={month + 1} value={month + 1}>
                {month + 1}
              </option>
            ))}
          </select>

          <select name="day" value={birthDate.day} onChange={handleInputChange} required>
            <option value="">태어난 일</option>
            {generateDayOptions().map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">계산하기</button>
      </form>
      {age !== null && koreanAge !== null && (
        <div>
          <p>당신의 만나이는 {age}세입니다.</p>
          <p>당신의 한국 나이는 {koreanAge}세입니다.</p>
        </div>
      )}
      <p className="description">
        이 도구는 사용자의 생년월일을 기반으로 현재 만나이와 한국 나이를 계산합니다. 만나이는 공식적인 나이 계산 방식 중 하나로, 현재 연도에서 생년월일을 기준으로 나이를 계산하고, 한국 나이는 태어난 연도를 기준으로 현재 연도에서 나이를 계산한 후 1살을 더합니다.
      </p>
    </div>
  );
};

export default AgeCalculator;
