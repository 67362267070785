import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

// 숫자에 쉼표를 추가하는 함수
const formatNumberWithComma = (number) => {
  if (!number) return '';
  return Math.round(number).toLocaleString(); // 세 자리마다 쉼표 추가, 소수점 제거
};

const MortgageCalculator = () => {
  const [homePrice, setHomePrice] = useState('');
  const [downPayment, setDownPayment] = useState('');
  const [loanTerm, setLoanTerm] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState(null);

  const calculateMortgage = (e) => {
    e.preventDefault();

    const principal = parseFloat(homePrice.replace(/,/g, '')) - parseFloat(downPayment.replace(/,/g, ''));
    const annualInterestRate = parseFloat(interestRate) / 100;
    const monthlyInterestRate = annualInterestRate / 12;
    const numberOfPayments = parseFloat(loanTerm) * 12;

    let payment;

    // 이자율이 0인 경우 단순 원금 나누기
    if (annualInterestRate === 0) {
      payment = principal / numberOfPayments;
    } else {
      payment = (principal * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
    }

    setMonthlyPayment(formatNumberWithComma(payment));
  };

  const resetCalculator = () => {
    setHomePrice('');
    setDownPayment('');
    setLoanTerm('');
    setInterestRate('');
    setMonthlyPayment(null);
  };

  return (
    <div className="content-box">
      <Helmet>
        <title>모기지 계산기</title>
        <meta name="description" content="모기지 상환 금액을 계산할 수 있는 도구입니다." />
        <meta name="keywords" content="모기지 계산기, 주택 대출, 대출 상환 계산, 모기지 금액 계산, 주택 담보 대출, 모기지 이자율, 대출 상환 계획, 대출 상환 도구, 금융 관리, 대출 상품 분석, 모기지 상환 도구, 모기지 이자 계산기, 모기지 금리, 대출 상환 추적, 대출 금액 관리, 주택 대출 계산기, 모기지 상환 관리, 대출 이자 계산, 금융 계산 도구, 대출 금액 분석" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>모기지 계산기</h2>
      <form onSubmit={calculateMortgage} className="mortgage-form">
        <div className="input-group">
          <label>주택 가격 (원)</label>
          <input
            type="text"
            value={formatNumberWithComma(homePrice)}
            onChange={(e) => setHomePrice(e.target.value.replace(/[^0-9]/g, ''))} // 숫자만 입력 가능
            placeholder="예: 300,000,000"
            required
          />
        </div>
        <div className="input-group">
          <label>다운페이먼트 (원)</label>
          <input
            type="text"
            value={formatNumberWithComma(downPayment)}
            onChange={(e) => setDownPayment(e.target.value.replace(/[^0-9]/g, ''))}
            placeholder="예: 60,000,000"
            required
          />
        </div>
        <div className="input-group">
          <label>대출 기간 (년)</label>
          <input
            type="number"
            value={loanTerm}
            onChange={(e) => setLoanTerm(e.target.value)}
            placeholder="예: 30"
            required
          />
        </div>
        <div className="input-group">
          <label>연 이자율 (%)</label>
          <input
            type="number"
            step="0.01"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            placeholder="예: 3.5"
            required
          />
        </div>
        <button type="submit">계산하기</button>
        <button type="button" onClick={resetCalculator} className="reset-button">
          초기화
        </button>
      </form>
      {monthlyPayment && (
        <div className="result">
          <h3>월 상환금액: {monthlyPayment} 원</h3>
        </div>
      )}
      <p className="description">
        모기지 계산기는 주택 가격, 다운페이먼트, 대출 기간, 이자율을 입력하여 매월 상환해야 할 금액을 계산해줍니다.<br /><br />

        - **주택 가격**: 구매하려는 주택의 총 금액을 입력합니다.<br />
        - **다운페이먼트**: 구매 시 선불로 지급하는 금액을 입력합니다.<br />
        - **대출 기간**: 대출을 상환하는 데 걸리는 기간을 년 단위로 입력합니다.<br />
        - **연 이자율**: 대출 이자율을 퍼센트(%)로 입력합니다.<br /><br />

        이를 통해 매월 지불해야 할 대출 상환액을 쉽게 확인할 수 있습니다.
      </p>
    </div>
  );
};

export default MortgageCalculator;
