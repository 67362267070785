import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

// 숫자를 한국어 금액으로 변환하는 함수
const formatAmountInKorean = (amount) => {
  if (!amount) return '';
  const num = parseInt(amount, 10);
  if (num >= 100000000) {
    return `${(num / 100000000).toFixed(0)} 억원`;
  } else if (num >= 10000) {
    return `${(num / 10000).toFixed(0)} 만원`;
  } else {
    return `${num} 원`;
  }
};

// 숫자에 쉼표를 추가하는 함수
const formatNumberWithComma = (number) => {
  if (!number && number !== 0) return '';
  return Number(number).toLocaleString(); // 세 자리마다 쉼표 추가
};

const LoanCalculator = () => {
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTerm, setLoanTerm] = useState('');
  const [termUnit, setTermUnit] = useState('years');
  const [repaymentMethod, setRepaymentMethod] = useState('equalPrincipalAndInterest');
  const [paymentSchedule, setPaymentSchedule] = useState([]); // 상환 스케줄 저장

  // 대출 계산 함수
  const calculateLoan = (e) => {
    e.preventDefault();

    const principal = parseFloat(loanAmount.replace(/,/g, '')); // 쉼표 제거 후 숫자로 변환
    const annualInterest = parseFloat(interestRate) / 100;
    let numberOfPayments;

    if (termUnit === 'years') {
      numberOfPayments = parseInt(loanTerm) * 12;
    } else {
      numberOfPayments = parseInt(loanTerm);
    }

    if (principal > 0 && numberOfPayments > 0) {
      let schedule = [];

      if (annualInterest === 0) {
        // 이자율이 0일 경우 무이자 계산
        const payment = principal / numberOfPayments;
        for (let i = 1; i <= numberOfPayments; i++) {
          schedule.push({
            month: i,
            payment: payment,
            principal: payment,
            interest: 0,
            remainingPrincipal: principal - payment * i,
          });
        }
      } else {
        const monthlyInterest = annualInterest / 12;

        switch (repaymentMethod) {
          case 'equalPrincipalAndInterest':
            // 원리금균등상환 계산
            const fixedPayment =
              (principal * monthlyInterest) /
              (1 - Math.pow(1 + monthlyInterest, -numberOfPayments));

            let remainingPrincipalEPI = principal;

            for (let i = 1; i <= numberOfPayments; i++) {
              const interestPayment = remainingPrincipalEPI * monthlyInterest;
              let principalRepayment = fixedPayment - interestPayment;

              // 마지막 달에 남은 원금을 모두 상환하도록 조정
              if (i === numberOfPayments) {
                principalRepayment = remainingPrincipalEPI;
              }

              remainingPrincipalEPI -= principalRepayment;

              // 남은 원금이 소수점 오차로 인해 음수가 되는 것을 방지
              if (remainingPrincipalEPI < 0.01) {
                remainingPrincipalEPI = 0;
              }

              schedule.push({
                month: i,
                payment: principalRepayment + interestPayment,
                principal: principalRepayment,
                interest: interestPayment,
                remainingPrincipal: remainingPrincipalEPI,
              });
            }
            break;

          case 'equalPrincipal':
            // 원금균등상환 계산
            const equalPrincipalRepayment = principal / numberOfPayments;
            let remainingPrincipalEP = principal;

            for (let i = 1; i <= numberOfPayments; i++) {
              const interestPayment = remainingPrincipalEP * monthlyInterest;
              let payment = equalPrincipalRepayment + interestPayment;

              // 마지막 달에 남은 원금을 모두 상환하도록 조정
              if (i === numberOfPayments) {
                payment = equalPrincipalRepayment + remainingPrincipalEP * monthlyInterest;
                remainingPrincipalEP = 0;
              } else {
                remainingPrincipalEP -= equalPrincipalRepayment;
                if (remainingPrincipalEP < 0.01) {
                  remainingPrincipalEP = 0;
                }
              }

              schedule.push({
                month: i,
                payment: payment,
                principal: equalPrincipalRepayment,
                interest: interestPayment,
                remainingPrincipal: remainingPrincipalEP < 0 ? 0 : remainingPrincipalEP,
              });
            }
            break;

          case 'lumpSum':
            // 만기일시상환 계산
            for (let i = 1; i <= numberOfPayments; i++) {
              let payment, principalRepayment, interestPayment;
              if (i === numberOfPayments) {
                // 마지막 달에 원금과 이자 상환
                interestPayment = principal * monthlyInterest;
                principalRepayment = principal;
                payment = interestPayment + principalRepayment;
              } else {
                // 그 외 달은 이자만 납부
                interestPayment = principal * monthlyInterest;
                principalRepayment = 0;
                payment = interestPayment;
              }

              schedule.push({
                month: i,
                payment: payment,
                principal: principalRepayment,
                interest: interestPayment,
                remainingPrincipal: i === numberOfPayments ? 0 : principal,
              });
            }
            break;

          default:
            break;
        }
      }

      setPaymentSchedule(schedule);
    } else {
      setPaymentSchedule([]);
    }
  };

  return (
    <div className="content-box loan-calculator">
      <Helmet>
        <title>대출 계산기</title>
        <meta name="description" content="대출 상환 금액을 계산할 수 있는 도구입니다." />
        <meta name="keywords" content="대출 계산기, 대출 상환 계산, 금융 도구, 금리 계산, 대출 금액, 이자율 계산기, 대출 계획, 상환 일정 계산, 대출 상환 관리, 금융 계획 도구, 대출 금액 추정, 대출 상환 분석, 대출 이자 계산기, 금융 관리, 대출 신청 계산기, 대출 조건 분석, 상환 기간 계산, 이자 지급 계산기, 재정 관리 도구, 대출 상품 비교" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>대출 계산기</h2>
      <form onSubmit={calculateLoan} className="loan-form">
        <div className="input-group">
          <label>대출 금액 (원)</label>
          <input
            type="text"
            value={formatNumberWithComma(loanAmount)} // 쉼표 형식으로 표시
            onChange={(e) => setLoanAmount(e.target.value.replace(/[^0-9]/g, ''))} // 숫자만 입력되도록
            placeholder="예: 50,000,000"
            required
          />
          <p>{formatAmountInKorean(loanAmount)}</p>
        </div>
        <div className="input-group">
          <label>연 이자율 (%)</label>
          <input
            type="number"
            step="0.01"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            placeholder="예: 3.5"
            required
          />
        </div>
        <div className="input-group">
          <label>기간</label>
          <input
            type="number"
            value={loanTerm}
            onChange={(e) => setLoanTerm(e.target.value)}
            placeholder="예: 20"
            required
          />
          <select value={termUnit} onChange={(e) => setTermUnit(e.target.value)}>
            <option value="years">년</option>
            <option value="months">월</option>
          </select>
        </div>
        <div className="input-group">
          <label>상환 방법</label>
          <select value={repaymentMethod} onChange={(e) => setRepaymentMethod(e.target.value)}>
            <option value="equalPrincipalAndInterest">원리금균등</option>
            <option value="equalPrincipal">원금균등</option>
            <option value="lumpSum">만기일시</option>
          </select>
        </div>
        <button type="submit">계산하기</button>
      </form>
      {paymentSchedule.length > 0 && (
        <div className="schedule">
          <h3>상환 스케줄</h3>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>월</th>
                  <th>상환액 (원)</th>
                  <th>원금 (원)</th>
                  <th>이자 (원)</th>
                  <th>남은 원금 (원)</th>
                </tr>
              </thead>
              <tbody>
                {paymentSchedule.map((payment) => (
                  <tr key={payment.month}>
                    <td>{payment.month}</td>
                    <td>{formatNumberWithComma(Math.round(payment.payment))}</td>
                    <td>{formatNumberWithComma(Math.round(payment.principal))}</td>
                    <td>{formatNumberWithComma(Math.round(payment.interest))}</td>
                    <td>{formatNumberWithComma(Math.round(payment.remainingPrincipal))}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <p className="description">
        이 대출 계산기는 대출 금액, 연 이자율, 대출 기간, 그리고 상환 방법을 입력하여
        매월 상환해야 할 금액을 계산해줍니다. 원리금균등, 원금균등, 만기일시 상환 등
        다양한 상환 방식을 지원합니다.<br /><br />

        - 원리금균등: 매달 동일한 금액을 상환합니다.<br />
        - 원금균등: 원금 상환액은 일정하며, 이자는 매월 감소합니다.<br />
        - 만기일시 상환: 매달 이자만 상환하고, 만기 시 원금을 일시 상환합니다.<br /><br />

        모든 월의 상환 스케줄을 표로 확인할 수 있습니다.
      </p>
    </div>
  );
};

export default LoanCalculator;
