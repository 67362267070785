import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';

const colors = ['red', 'blue', 'green', 'yellow', 'purple', 'orange', 'pink', 'brown', 'gray', 'cyan', 'lime', 'gold'];
const colorNames = ['빨강', '파랑', '초록', '노랑', '보라', '주황', '핑크', '갈색', '회색', '청록', '라임', '금색'];

const ColorMatchingGame = () => {
  const [targetColor, setTargetColor] = useState('');
  const [targetColorName, setTargetColorName] = useState('');
  const [shuffledColors, setShuffledColors] = useState([]);
  const [score, setScore] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [round, setRound] = useState(0);
  const maxRounds = 5;
  const [gameEnded, setGameEnded] = useState(false);

  const shuffleColors = useCallback(() => {
    const shuffled = [...colors].sort(() => Math.random() - 0.5);
    setShuffledColors(shuffled);
  }, []);

  const generateRandomColor = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    const randomColor = colors[randomIndex];
    setTargetColor(randomColor);
    setTargetColorName(colorNames[randomIndex]);
    setClicked(false);
    shuffleColors(); // shuffleColors 사용

    // 목표 색깔을 제외한 색깔들로 새 배열 생성
    const otherColors = colors.filter(color => color !== randomColor);
    const randomOtherColors = [];
    while (randomOtherColors.length < 3) { // 3개의 다른 색깔 추가
      const randIndex = Math.floor(Math.random() * otherColors.length);
      const color = otherColors[randIndex];
      if (!randomOtherColors.includes(color)) {
        randomOtherColors.push(color);
      }
    }
    // 목표 색깔과 무작위로 선택한 색깔들을 합쳐서 셔플
    setShuffledColors([randomColor, ...randomOtherColors].sort(() => Math.random() - 0.5));
  }, [shuffleColors]);

  const handleColorClick = (color) => {
    if (!clicked && round <= maxRounds) {
      if (color === targetColor) {
        setScore(score + 1);
      }
      setClicked(true);

      if (round < maxRounds) {
        setTimeout(() => {
          setRound((prevRound) => prevRound + 1);
          generateRandomColor();
        }, 1000);
      } else {
        setGameEnded(true);
      }
    }
  };

  const startNewGame = () => {
    setScore(0);
    setRound(1);
    setGameEnded(false);
    generateRandomColor();
  };

  useEffect(() => {
    if (round === 1) {
      generateRandomColor();
    }
  }, [round, generateRandomColor]);

  return (
    <div className="content-box color-matching-game">
      <Helmet>
        <title>색깔 맞추기 게임</title>
        <meta name="description" content="색깔을 맞추는 재미있는 게임입니다." />
        <meta name="keywords" content="색깔 맞추기 게임, 색상 게임, 퍼즐 게임, 색상 인식, 색깔 테스트, 색깔 비교, 색상 차이, 색상 매칭, 시각 테스트, 색채 게임, 집중력 게임, 색상 학습, 색깔 맞추기, 반응 속도 게임, 색상 조합 게임, 색상 구분 게임, 색상 기억력 게임, 색상 감별 게임, 두뇌 훈련, 색상 분석 게임" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>색깔 맞추기 게임</h2>
      {!gameEnded && round === 0 && (
        <button onClick={startNewGame}>새 게임 시작</button>
      )}
      {round > 0 && round <= maxRounds && (
        <>
          <p>다음 색깔과 같은 색을 클릭하세요: <span style={{ fontWeight: 'bold', color: targetColor }}>{targetColorName}</span></p>
          <div className="color-grid">
            {shuffledColors.map((color) => (
              <div
                key={color}
                style={{
                  backgroundColor: color,
                  width: '100px',
                  height: '100px',
                  display: 'inline-block',
                  margin: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => handleColorClick(color)}
              />
            ))}
          </div>
          <p>현재 라운드: {round}/{maxRounds}</p>
          <p>점수: {score}</p>
        </>
      )}
      {gameEnded && (
        <>
          <p>게임 종료! 최종 점수: {score}점</p>
          <button onClick={startNewGame}>다시 시작</button>
        </>
      )}
      <p className="description">
          이 색깔 맞추기 게임은 화면에 나타난 글자 색상과 일치하는 박스를 선택하는 게임입니다. 화면에 나타난 글자와 실제 색상은 다를 수 있으니 주의 깊게 선택하세요. 여러 라운드 동안 진행되며 최종 점수를 확인할 수 있습니다.
      </p>
    </div>
  );
};

export default ColorMatchingGame;
