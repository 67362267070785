// TicTacToe.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const TicTacToe = () => {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [isXNext, setIsXNext] = useState(true);
  const [winner, setWinner] = useState(null);

  const handleClick = (index) => {
    if (board[index] || winner) return; // 이미 클릭된 칸이거나 승자가 있으면 무시

    const newBoard = [...board];
    newBoard[index] = isXNext ? 'X' : 'O';
    setBoard(newBoard);
    setIsXNext(!isXNext);

    const checkWinner = calculateWinner(newBoard);
    if (checkWinner) {
      setWinner(checkWinner);
    } else if (newBoard.every((square) => square)) {
      setWinner('Draw'); // 모든 칸이 채워졌지만 승자가 없을 때 무승부 처리
    }
  };

  const calculateWinner = (squares) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];

    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        return squares[a];
      }
    }
    return null;
  };

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setIsXNext(true);
    setWinner(null);
  };

  return (
    <div className="content-box tictactoe">
      <Helmet>
        <title>틱택토 게임</title>
        <meta name="description" content="틱택토 게임을 즐길 수 있는 도구입니다." />
        <meta name="keywords" content="틱택토 게임, 틱택토, 퍼즐 게임, 오목 게임, 전략 게임, 3목 게임, 두뇌 게임, 논리 게임, 순발력 게임, 틱택토 전략, 오목판 게임, 틱택토 퍼즐, 두뇌 훈련, 간단한 퍼즐 게임, 틱택토 보드, 친구와 함께하는 게임, 빠른 게임, 논리 퍼즐, 퍼즐 도전, 틱택토 두뇌 도전" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>틱택토 게임</h2>
      <div className="board">
        {board.map((square, index) => (
          <div
            key={index}
            className={`square ${square ? 'filled' : ''}`}
            onClick={() => handleClick(index)}
          >
            {square}
          </div>
        ))}
      </div>
      {winner && (
        <div className="game-info">
          {winner === 'Draw' ? (
            <p>무승부입니다!</p>
          ) : (
            <p>승자: {winner}</p>
          )}
          <button onClick={resetGame}>다시 하기</button>
        </div>
      )}
      <p className="description">
          이 틱택토 게임은 3x3 배열에서 X 또는 O로 승리 조건을 맞추는 게임입니다. 상대와 번갈아가며 자신의 마크를 배치하고 가로, 세로, 또는 대각선으로 3개의 마크를 먼저 완성하는 플레이어가 승리합니다.
      </p>
    </div>
  );
};

export default TicTacToe;
