import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const DueDateCalculator = () => {
  const [lmp, setLmp] = useState(''); // 마지막 생리일
  const [dueDate, setDueDate] = useState(null); // 출산 예정일

  const calculateDueDate = (e) => {
    e.preventDefault();

    const lmpDate = new Date(lmp);
    const dueDate = new Date(lmpDate.setDate(lmpDate.getDate() + 280)); // 280일 추가
    setDueDate(dueDate.toLocaleDateString()); // 날짜 포맷으로 변환하여 저장
  };

  const resetCalculator = () => {
    setLmp('');
    setDueDate(null);
  };

  return (
    <div className="content-box due-date-calculator">
      <Helmet>
        <title>출산 예정일 계산기</title>
        <meta name="description" content="출산 예정일을 계산할 수 있는 도구입니다." />
        <meta name="keywords" content="출산 예정일 계산기, 출산일 계산, 임신 도구, 출산 준비, 임신 기간 계산, 예정일 추정, 출산 날짜 추적, 임신 주수 계산, 출산 계획, 임신 기간 추적, 출산 예측, 예정일 추적 도구, 임신 중기 계산, 임신 상태 관리, 출산 날짜 확인, 출산 시기 계산, 임신 진척도 계산기, 임신 주차 계산, 임신 관리 도구, 임산부 도구" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>출산 예정일 계산기</h2>
      <form onSubmit={calculateDueDate}>
        <div className="input-group">
          <label>마지막 생리 시작일</label>
          <input
            type="date"
            value={lmp}
            onChange={(e) => setLmp(e.target.value)}
            required
          />
        </div>
        <button type="submit">계산하기</button>
        <button type="button" onClick={resetCalculator} className="reset-button">
          초기화
        </button>
      </form>
      {dueDate && (
        <div className="result">
          <h3>출산 예정일: {dueDate}</h3>
        </div>
      )}
      <p className="description">
        이 계산기는 마지막 생리일(LMP)을 기준으로 출산 예정일을 예측합니다. <br />
        임신은 대개 마지막 생리일로부터 약 280일 후에 출산하게 됩니다.
      </p>
    </div>
  );
};

export default DueDateCalculator;
