import React, { useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// 숫자에 세 자리마다 쉼표를 추가하는 함수
const formatNumberWithComma = (number) => {
  if (!number) return '';
  return Math.round(number).toLocaleString(); // 세 자리마다 쉼표 추가, 소수점 제거 및 반올림
};

// 기본 복리 계산 함수
const calculateCompoundInterestBasic = (P, r, n, t) => {
  if (r === 0) return P; // 이자율이 0일 경우 원금 그대로 반환
  return Math.round(P * Math.pow(1 + r / n, n * t));
};

// 적립식 복리 계산 함수
const calculateCompoundInterestRegular = (P, r, n, t, A) => {
  if (r === 0) return P + A * n * t; // 이자율이 0일 경우 적립만 더함
  let totalAmount = P;
  for (let i = 1; i <= n * t; i++) {
    totalAmount = (totalAmount + A) * (1 + r / n);
  }
  return Math.round(totalAmount);
};

const CompoundInterestCalculator = () => {
  const [principal, setPrincipal] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [timesCompounded, setTimesCompounded] = useState('');
  const [years, setYears] = useState('');
  const [depositAmount, setDepositAmount] = useState(''); // 적립식 복리를 위한 변수
  const [futureValue, setFutureValue] = useState(null);
  const [calcType, setCalcType] = useState('basic'); // 'basic' 또는 'regular' 선택

  // calculateFutureValue를 useCallback으로 래핑하여 의존성 문제 해결
  const calculateFutureValue = useCallback(() => {
    const P = parseFloat(principal.replace(/,/g, '')); // 쉼표 제거 후 숫자로 변환
    const r = parseFloat(interestRate) / 100;
    const n = parseFloat(timesCompounded);
    const t = parseFloat(years);
    const A = parseFloat(depositAmount.replace(/,/g, '')) || 0; // 적립 금액 쉼표 제거 후 숫자로 변환

    let result;
    if (calcType === 'basic') {
      result = calculateCompoundInterestBasic(P, r, n, t);
    } else {
      result = calculateCompoundInterestRegular(P, r, n, t, A);
    }

    setFutureValue(formatNumberWithComma(result.toFixed(0)));
  }, [principal, interestRate, timesCompounded, years, depositAmount, calcType]);

  useEffect(() => {
    if (principal && interestRate && timesCompounded && years) {
      calculateFutureValue();
    }
  }, [principal, interestRate, timesCompounded, years, depositAmount, calcType, calculateFutureValue]);

  const resetCalculator = () => {
    setPrincipal('');
    setInterestRate('');
    setTimesCompounded('');
    setYears('');
    setDepositAmount('');
    setFutureValue(null);
  };

  return (
    <div className="content-box compound-interest-calculator">
      <Helmet>
        <title>복리 계산기</title>
        <meta name="description" content="복리를 계산할 수 있는 도구입니다." />
        <meta name="keywords" content="복리 계산기, 이자 계산, 금융 계산, 재정 관리, 복리, 복리 수익률, 이자율 계산, 재테크 도구, 복리 공식, 금융 이자, 복리 투자, 복리 효과, 투자 수익 계산기, 이자 수익 계산, 재정 계획, 복리 투자의 장점, 복리 이익, 복리 증대, 금융 계산 도구, 재정 분석" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>복리 계산기</h2>
      <form onSubmit={(e) => e.preventDefault()} className="compound-form">
        <div className="input-group">
          <label>계산 방식</label>
          <select value={calcType} onChange={(e) => setCalcType(e.target.value)}>
            <option value="basic">기본 복리 계산</option>
            <option value="regular">적립식 복리 계산</option>
          </select>
        </div>
        <div className="input-group">
          <label>초기 투자 금액 (원)</label>
          <input
            type="text"
            value={formatNumberWithComma(principal)}  // 쉼표 형식으로 표시
            onChange={(e) => setPrincipal(e.target.value.replace(/[^0-9]/g, ''))}  // 숫자만 입력되도록
            placeholder="예: 1,000,000"
            required
          />
        </div>
        <div className="input-group">
          <label>연 이자율 (%)</label>
          <input
            type="number"
            step="0.01"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            placeholder="예: 5"
            required
          />
        </div>
        <div className="input-group">
          <label>복리 횟수 (연)</label>
          <select
            value={timesCompounded}
            onChange={(e) => setTimesCompounded(e.target.value)}
            required
          >
            <option value="">선택하세요</option>
            <option value={1}>연 1회</option>
            <option value={2}>연 2회</option>
            <option value={4}>연 4회</option>
            <option value={12}>연 12회</option>
            <option value={365}>연 365회</option>
          </select>
        </div>
        <div className="input-group">
          <label>기간 (년)</label>
          <input
            type="number"
            value={years}
            onChange={(e) => setYears(e.target.value)}
            placeholder="예: 10"
            required
          />
        </div>
        {/* 적립식 복리일 경우에만 표시되는 입력 필드 */}
        {calcType === 'regular' && (
          <div className="input-group">
            <label>매월 적립 금액 (원)</label>
            <input
              type="text"
              value={formatNumberWithComma(depositAmount)}  // 쉼표 형식으로 표시
              onChange={(e) => setDepositAmount(e.target.value.replace(/[^0-9]/g, ''))}  // 숫자만 입력되도록
              placeholder="예: 100,000"
            />
          </div>
        )}
        <button type="button" onClick={resetCalculator} className="reset-button">
          초기화
        </button>
      </form>
      {futureValue && (
        <div className="result">
          <h3>미래 가치: {futureValue} 원</h3>
        </div>
      )}
      <p className="description">
        복리 계산기는 초기 투자 금액과 이자율, 투자 기간을 입력하여 자산이 시간에 따라 어떻게 증가할지 예측하는 도구입니다. <br />
        <br />
        - **기본 복리 계산**: 초기 투자금에 대해 정해진 이자율과 복리 횟수를 기반으로 미래 가치를 계산합니다. <br />
        - **적립식 복리 계산**: 초기 투자금 외에도 매월 일정 금액을 추가로 투자하며, 이 금액 또한 복리로 계산됩니다. 이를 통해 장기적으로 더 많은 자산을 쌓을 수 있습니다.<br />
        <br />
        복리는 시간이 지날수록 그 힘이 커지며, 긴 투자 기간과 높은 복리 횟수는 투자 성장을 극대화하는 데 중요한 요소입니다.
      </p>
    </div>
  );
};

export default CompoundInterestCalculator;
