import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

const UnitConverter = () => {
  const [inputValue, setInputValue] = useState('');
  const [category, setCategory] = useState('length');
  const [inputUnit, setInputUnit] = useState('');
  const [convertedUnit, setConvertedUnit] = useState('');
  const [convertedValue, setConvertedValue] = useState('');

  // 단위 변환 객체 정의
  const unitConversions = useMemo(() => ({
    length: { km: 1, m: 1000, cm: 100000, mm: 1000000, mi: 0.621371, yd: 1093.61, ft: 3280.84 },
    dataTransferRate: { 'bps': 1, 'kbps': 1e-3, 'Mbps': 1e-6, 'Gbps': 1e-9 },
    dataSize: { byte: 1, kilobyte: 0.001, megabyte: 1e-6, gigabyte: 1e-9, terabyte: 1e-12 },
    area: { sqm: 1, sqkm: 1e-6, sqft: 10.7639, acre: 0.000247105 },
    volume: { liter: 1, milliliter: 1000, gallon: 0.264172, pint: 2.11338 },
    speed: { 'm/s': 1, 'km/h': 3.6, 'mi/h': 2.23694 },
    time: { second: 1, minute: 1/60, hour: 1/3600, day: 1/86400 },
    pressure: { pascal: 1, bar: 1e-5, atm: 9.86923e-6, psi: 0.000145038 },
    energy: { joule: 1, kilojoule: 1e-3, calorie: 0.239006, kilocalorie: 0.000239006 },
    fuelEfficiency: { 'km/l': 1, 'mpg': 2.35215 },
    temperature: {
      celsius: (val) => val,
      fahrenheit: (val) => (val * 9) / 5 + 32,
      kelvin: (val) => val + 273.15
    },
    frequency: { hertz: 1, kilohertz: 1e-3, megahertz: 1e-6, gigahertz: 1e-9 },
    mass: { kg: 1, g: 1000, mg: 1000000, lb: 2.20462, oz: 35.274 },
    angle: { radian: 1, degree: 57.2958 }
  }), []);

  const unitLabels = {
    km: '킬로미터', m: '미터', cm: '센티미터', mm: '밀리미터', mi: '마일', yd: '야드', ft: '피트',
    bps: '비트/초', kbps: '킬로비트/초', Mbps: '메가비트/초', Gbps: '기가비트/초',
    byte: '바이트', kilobyte: '킬로바이트', megabyte: '메가바이트', gigabyte: '기가바이트', terabyte: '테라바이트',
    sqm: '제곱미터', sqkm: '제곱킬로미터', sqft: '제곱피트', acre: '에이커',
    liter: '리터', milliliter: '밀리리터', gallon: '갤런', pint: '파인트',
    'm/s': '미터/초', 'km/h': '킬로미터/시간', 'mi/h': '마일/시간',
    second: '초', minute: '분', hour: '시간', day: '일',
    pascal: '파스칼', bar: '바', atm: '기압', psi: 'PSI',
    joule: '줄', kilojoule: '킬로줄', calorie: '칼로리', kilocalorie: '킬로칼로리',
    'km/l': '킬로미터/리터', 'mpg': '마일/갤런',
    celsius: '섭씨', fahrenheit: '화씨', kelvin: '켈빈',
    hertz: '헤르츠', kilohertz: '킬로헤르츠', megahertz: '메가헤르츠', gigahertz: '기가헤르츠',
    kg: '킬로그램', g: '그램', mg: '밀리그램', lb: '파운드', oz: '온스',
    radian: '라디안', degree: '도'
  };

  useEffect(() => {
    const units = Object.keys(unitConversions[category]);
    setInputUnit(units[0]);
    setConvertedUnit(units[1] || units[0]);
  }, [category, unitConversions]);

  const handleConvert = useCallback(() => {
    let value = parseFloat(inputValue);
    if (isNaN(value)) {
      setConvertedValue('');
      return;
    }

    if (category === 'temperature') {
      let tempValue;
      if (inputUnit === 'celsius') {
        tempValue = value;
      } else if (inputUnit === 'fahrenheit') {
        tempValue = (value - 32) * 5 / 9;
      } else {
        tempValue = value - 273.15;
      }

      let result;
      if (convertedUnit === 'fahrenheit') {
        result = (tempValue * 9 / 5) + 32;
      } else if (convertedUnit === 'kelvin') {
        result = tempValue + 273.15;
      } else {
        result = tempValue;
      }
      setConvertedValue(parseFloat(result.toPrecision(10))); // 유효 자릿수를 기준으로 표시
    } else {
      const baseValue = value / unitConversions[category][inputUnit];
      const result = baseValue * unitConversions[category][convertedUnit];
      setConvertedValue(parseFloat(result.toPrecision(10))); // 유효 자릿수 10자리까지
    }
  }, [inputValue, inputUnit, convertedUnit, category, unitConversions]);

  useEffect(() => {
    if (inputValue) handleConvert();
  }, [inputValue, inputUnit, convertedUnit, handleConvert]);

  return (
    <div className="content-box unit-converter">
      <Helmet>
        <title>단위 변환기</title>
        <meta name="description" content="다양한 단위를 변환할 수 있는 도구입니다." />
        <meta name="keywords" content="단위 변환기, 길이 변환, 무게 변환, 시간 변환, 온도 변환, 킬로미터, 마일, 미터, 단위 계산기, 단위 변환 도구, 킬로그램, 파운드, 센티미터, 인치, 온스, 화씨, 섭씨, 시간 단위 변환, 미터법 변환, 단위 변환 계산기" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h1>단위 변환기</h1>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="input-group">
          <label>카테고리 선택:</label>
          <select value={category} onChange={(e) => setCategory(e.target.value)}>
            <option value="length">길이</option>
            <option value="dataTransferRate">데이터 전송 속도</option>
            <option value="dataSize">데이터 크기</option>
            <option value="area">면적</option>
            <option value="volume">부피</option>
            <option value="speed">속도</option>
            <option value="time">시간</option>
            <option value="pressure">압력</option>
            <option value="energy">에너지</option>
            <option value="fuelEfficiency">연비</option>
            <option value="temperature">온도</option>
            <option value="frequency">주파수</option>
            <option value="mass">질량</option>
            <option value="angle">평면각</option>
          </select>
        </div>

        <div className="input-group">
          <label>값 입력:</label>
          <input
            type="number"
            placeholder="값 입력"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>

        <div className="input-group">
          <label>단위 선택:</label>
          <div className="unit-selectors">
            <select value={inputUnit} onChange={(e) => setInputUnit(e.target.value)}>
              {Object.keys(unitConversions[category]).map((unit) => (
                <option key={unit} value={unit}>
                  {unitLabels[unit]}
                </option>
              ))}
            </select>

            <span className="arrow">→</span>

            <select value={convertedUnit} onChange={(e) => setConvertedUnit(e.target.value)}>
              {Object.keys(unitConversions[category])
                .filter((unit) => unit !== inputUnit)
                .map((unit) => (
                  <option key={unit} value={unit}>
                    {unitLabels[unit]}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {/* 변환된 값 표시 */}
        {convertedValue && <p>변환된 값: {convertedValue} {unitLabels[convertedUnit]}</p>}

        <p className="description">
          다양한 단위를 지원하는 변환기입니다. 값을 입력하고 단위를 선택하여 원하는 결과를 얻을 수 있습니다.
        </p>
      </form>
    </div>
  );
};

export default UnitConverter;
