// DateCounter.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const DateCounter = () => {
  const [targetDate, setTargetDate] = useState('');
  const [daysLeft, setDaysLeft] = useState(null);

  const calculateDaysLeft = (e) => {
    e.preventDefault();

    const today = new Date();
    const selectedDate = new Date(targetDate);
    const timeDiff = selectedDate - today;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    setDaysLeft(daysDiff);
  };

  return (
    <div className="content-box date-counter">
      <Helmet>
        <title>날짜 카운터</title>
        <meta name="description" content="특정 날짜까지 남은 일수를 계산하는 도구입니다." />
        <meta name="keywords" content="날짜 계산기, 디데이 계산기, 날짜 카운트, 일정 관리, 남은 시간 계산, 기념일 계산기, 목표 날짜, 시간 카운트다운, 경과 시간 계산, 남은 날 계산기, 일정 카운트, 날짜 비교, 시간 계산 도구, 타임라인 계산기, 이벤트 카운트다운, 목표일 계산기, 기념일 추적, 시간 관리 도구, 카운트다운 타이머, 시간 측정기" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h1 className="page-title">날짜 카운터</h1>
      <form onSubmit={calculateDaysLeft}>
        <div className="input-group">
          <input
            type="date"
            value={targetDate}
            onChange={(e) => setTargetDate(e.target.value)}
            required
          />
        </div>

        <button type="submit">날짜 계산</button>
      </form>

      {daysLeft !== null && <p>목표일까지 {daysLeft}일 남았습니다.</p>}

      <p className="description">
        이 도구는 목표 날짜를 선택하면 오늘부터 해당 날짜까지 남은 일수를 계산해줍니다. 예를 들어 중요한 이벤트나 마감일을 추적하는 데 유용합니다.
      </p>
    </div>
  );
};

export default DateCounter;
