import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const BodyFatCalculator = () => {
  const [gender, setGender] = useState('male');
  const [age, setAge] = useState('');
  const [height, setHeight] = useState('');
  const [neck, setNeck] = useState('');
  const [waist, setWaist] = useState('');
  const [hip, setHip] = useState('');
  const [bodyFat, setBodyFat] = useState(null);

  const calculateBodyFat = (e) => {
    e.preventDefault();

    const h = parseFloat(height);
    const n = parseFloat(neck);
    const w = parseFloat(waist);
    const hp = parseFloat(hip);

    if (gender === 'male') {
      const bf =
        495 /
          (1.0324 -
            0.19077 * Math.log10(w - n) +
            0.15456 * Math.log10(h)) -
        450;
      setBodyFat(bf.toFixed(2));
    } else if (gender === 'female') {
      const bf =
        495 /
          (1.29579 -
            0.35004 * Math.log10(w + hp - n) +
            0.221 * Math.log10(h)) -
        450;
      setBodyFat(bf.toFixed(2));
    }
  };

  const resetCalculator = () => {
    setGender('male');
    setAge('');
    setHeight('');
    setNeck('');
    setWaist('');
    setHip('');
    setBodyFat(null);
  };

  return (
    <div className="content-box bodyfat-calculator">
      <Helmet>
        <title>체지방률 계산기</title>
        <meta name="description" content="체지방률을 계산할 수 있는 도구입니다." />
        <meta name="keywords" content="체지방률 계산기, 체지방 계산, 건강 관리, 체지방 비율, 신체 지방, 다이어트, 지방 분석, 신체 건강, 체지방 지수, 피트니스, 건강한 체중, 신체 비율, 지방량 계산, 체지방 관리, 운동 계획, 지방 측정 도구, 건강 지표, 체중 감량, 피트니스 도구, 지방율 계산" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>체지방률 계산기</h2>
      <form onSubmit={calculateBodyFat} className="bodyfat-form">
        <div className="input-group">
          <label>성별</label>
          <select value={gender} onChange={(e) => setGender(e.target.value)}>
            <option value="male">남성</option>
            <option value="female">여성</option>
          </select>
        </div>
        <div className="input-group">
          <label>나이 (년)</label>
          <input
            type="number"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            placeholder="예: 30"
            required
          />
        </div>
        <div className="input-group">
          <label>신장 (cm)</label>
          <input
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            placeholder="예: 175"
            required
          />
        </div>
        <div className="input-group">
          <label>목 둘레 (cm)</label>
          <input
            type="number"
            value={neck}
            onChange={(e) => setNeck(e.target.value)}
            placeholder="예: 40"
            required
          />
        </div>
        <div className="input-group">
          <label>허리 둘레 (cm)</label>
          <input
            type="number"
            value={waist}
            onChange={(e) => setWaist(e.target.value)}
            placeholder="예: 85"
            required
          />
        </div>
        {gender === 'female' && (
          <div className="input-group">
            <label>엉덩이 둘레 (cm)</label>
            <input
              type="number"
              value={hip}
              onChange={(e) => setHip(e.target.value)}
              placeholder="예: 95"
              required
            />
          </div>
        )}
        <button type="submit">계산하기</button>
        <button type="button" onClick={resetCalculator} className="reset-button">
          초기화
        </button>
      </form>
      {bodyFat && (
        <div className="result">
          <h3>체지방률: {bodyFat} %</h3>
        </div>
      )}

      <p className="description">
        체지방률 계산기는 신체의 특정 부위 치수를 이용하여 체지방 비율을 계산하는 도구입니다. 이 계산기는 성별에 따라 다른 공식을 사용하며, 다음과 같은 정보를 입력하여 체지방률을 계산합니다:
        <br /><br />
        - **성별**: 남성과 여성은 서로 다른 체지방 공식을 사용합니다.<br />
        - **나이**: 나이를 기준으로 체지방률을 예측합니다.<br />
        - **신장**: 신체의 전체 길이를 입력합니다.<br />
        - **목 둘레**: 목의 둘레를 입력합니다.<br />
        - **허리 둘레**: 허리 둘레는 체지방률을 결정하는 중요한 요소입니다.<br />
        - **엉덩이 둘레**: 여성의 경우, 엉덩이 둘레도 추가로 고려됩니다.<br /><br />
        이 계산기는 대략적인 체지방률을 제공하며, 정확한 측정을 위해 전문적인 진단을 권장합니다.
      </p>
    </div>
  );
};

export default BodyFatCalculator;
