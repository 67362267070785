import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const Todo = () => {
  const [task, setTask] = useState('');
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const storedTasks = JSON.parse(localStorage.getItem('tasks'));
    if (storedTasks) {
      setTasks(storedTasks);
    }
  }, []);

  const addTask = (e) => {
    e.preventDefault();

    const newTasks = [...tasks, task];
    setTasks(newTasks);
    localStorage.setItem('tasks', JSON.stringify(newTasks));
    setTask('');
  };

  const deleteTask = (index) => {
    const updatedTasks = tasks.filter((_, i) => i !== index);
    setTasks(updatedTasks);
    localStorage.setItem('tasks', JSON.stringify(updatedTasks));
  };

  return (
    <div className="content-box todo-list">
      <Helmet>
        <title>To-do 리스트</title>
        <meta name="description" content="할 일을 관리할 수 있는 To-do 리스트 도구입니다." />
        <meta name="keywords" content="To-do 리스트, 할 일 관리, 일정 관리, 작업 관리, 생산성, 체크리스트, 할 일 추적, 일정 추적, 할 일 계획, 일정 도구, 작업 목록 관리, 우선순위 관리, 할 일 기록, 시간 관리 도구, 목표 설정, 할 일 알림, 할 일 달성, 작업 분배, 생산성 향상 도구, 업무 관리" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h1 className="page-title">To-do 리스트</h1>
      <form onSubmit={addTask}>
        <div className="input-group">
          <input
            type="text"
            placeholder="할 일 추가"
            value={task}
            onChange={(e) => setTask(e.target.value)}
            required
          />
        </div>
        <div className="button-container">
          <button type="submit">추가하기</button>
        </div>
      </form>
      <ul>
        {tasks.map((taskItem, index) => (
          <li key={index}>
            {taskItem}
            <button className="delete-button" onClick={() => deleteTask(index)}>삭제</button>
          </li>
        ))}
      </ul>
      <p className="description">
        이 도구는 할 일 목록을 작성하고 관리할 수 있는 간단한 To-do 리스트입니다.
        목록에 항목을 추가하고 완료된 작업을 추적하는 데 사용할 수 있습니다.<br /><br />
        주의: 이 도구는 데이터를 로컬스토리지에 저장합니다. 하지만 브라우저의 쿠키 및 사이트 데이터를 삭제하거나 브라우저 설정에 따라 자동으로 데이터를 정리하는 경우, 저장된 내역이 사라질 수 있습니다. 데이터를 안전하게 보관하려면 브라우저 설정을 확인해주세요.
      </p>
    </div>
  );
};

export default Todo;
