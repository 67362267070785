import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const Memo = () => {
  const [title, setTitle] = useState('');
  const [memo, setMemo] = useState('');
  const [savedMemos, setSavedMemos] = useState([]);

  // 컴포넌트가 처음 렌더링될 때 로컬 스토리지에서 메모 리스트를 불러옴
  useEffect(() => {
    const storedMemos = JSON.parse(localStorage.getItem('savedMemos'));
    if (storedMemos) {
      setSavedMemos(storedMemos);
    }
  }, []);

  // 메모를 저장하는 함수
  const saveMemo = (e) => {
    e.preventDefault();

    const newMemo = { title, memo, isOpen: false }; // 메모 제목과 내용을 객체로 저장
    const newMemos = [...savedMemos, newMemo];
    setSavedMemos(newMemos);
    localStorage.setItem('savedMemos', JSON.stringify(newMemos)); // 메모 리스트를 로컬 스토리지에 저장
    setTitle('');
    setMemo('');
  };

  // 메모 삭제 함수
  const deleteMemo = (index) => {
    const updatedMemos = savedMemos.filter((_, i) => i !== index);
    setSavedMemos(updatedMemos);
    localStorage.setItem('savedMemos', JSON.stringify(updatedMemos));
  };

  // 제목 클릭 시 메모 내용 토글
  const toggleMemo = (index) => {
    const updatedMemos = savedMemos.map((memo, i) => {
      if (i === index) {
        return { ...memo, isOpen: !memo.isOpen }; // isOpen 상태를 토글
      }
      return memo;
    });
    setSavedMemos(updatedMemos);
  };

  return (
    <div className="content-box memo">
      <Helmet>
        <title>메모장</title>
        <meta name="description" content="간단한 메모를 작성할 수 있는 도구입니다." />
        <meta name="keywords" content="메모장, 메모 작성, 간단한 노트, 할 일 기록, 텍스트 저장, 빠른 메모, 기록 도구, 메모 작성 도구, 온라인 메모장, 텍스트 관리, 할 일 관리, 메모 목록, 체크리스트, 간편 노트, 메모 정리, 텍스트 기록 도구, 간단한 기록, 할 일 정리, 메모장 앱, 온라인 기록 도구" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>간단한 메모장</h2>
      <form onSubmit={saveMemo}>
        <div className="input-group">
          <input
            type="text"
            placeholder="메모 제목을 입력하세요"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <textarea
            placeholder="메모 내용을 입력하세요"
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            required
          />
        </div>
        <button type="submit">메모 저장</button>
      </form>
      {savedMemos.length > 0 && (
        <div>
          <h3>저장된 메모:</h3>
          <ul>
            {savedMemos.map((savedMemo, index) => (
              <li key={index}>
                <div>
                  <strong onClick={() => toggleMemo(index)} style={{ cursor: 'pointer' }}>
                    {savedMemo.title}
                  </strong>
                  <button onClick={() => deleteMemo(index)} className="delete-button">삭제</button>
                </div>
                {savedMemo.isOpen && <p>{savedMemo.memo}</p>} {/* isOpen 상태에 따라 내용 표시 */}
              </li>
            ))}
          </ul>
        </div>
      )}
      <p className="description">메모 제목을 클릭하여 내용을 확인할 수 있습니다.<br /><br />
      주의: 이 도구는 데이터를 로컬스토리지에 저장합니다. 하지만 브라우저의 쿠키 및 사이트 데이터를 삭제하거나 브라우저 설정에 따라 자동으로 데이터를 정리하는 경우, 저장된 내역이 사라질 수 있습니다. 데이터를 안전하게 보관하려면 브라우저 설정을 확인해주세요.
      </p>
    </div>
  );
};

export default Memo;
