// ColorConverter.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const ColorConverter = () => {
  const [hex, setHex] = useState('');
  const [rgb, setRgb] = useState('');

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgb(${r}, ${g}, ${b})`;
  };

  const convertHexToRgb = (e) => {
    e.preventDefault();

    if (hex.startsWith('#') && (hex.length === 7 || hex.length === 4)) {
      if (hex.length === 4) {
        // 3자리 HEX 코드 지원
        const r = parseInt(hex[1] + hex[1], 16);
        const g = parseInt(hex[2] + hex[2], 16);
        const b = parseInt(hex[3] + hex[3], 16);
        setRgb(`rgb(${r}, ${g}, ${b})`);
      } else {
        setRgb(hexToRgb(hex));
      }
    } else {
      setRgb('잘못된 형식입니다.');
    }
  };

  return (
    <div className="content-box color-converter">
      <Helmet>
        <title>색상 코드 변환기</title>
        <meta name="description" content="색상 코드를 변환할 수 있는 도구입니다." />
        <meta name="keywords" content="색상 코드 변환기, RGB, HEX, 색상 변환, 색상 코드, 컬러 변환, 색상 조합, 색상표 변환, 컬러 코드, 컬러 매칭, 색상 비교, 디자인 도구, 색상 추출기, 컬러 팔레트, RGB to HEX, 색상 분석, 색상 관리 도구, 웹 디자인 도구, 그래픽 디자인, 색상 코드 생성기, 색상 비교 도구" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>색상 코드 변환기</h2>
      <form onSubmit={convertHexToRgb}>
        <div className="input-group">
          <input
            type="text"
            placeholder="#000000"
            value={hex}
            onChange={(e) => setHex(e.target.value)}
            required
          />
        </div>
        <button type="submit">변환하기</button>
      </form>
      {rgb && <p>{rgb}</p>}
      <p className="description">이 도구는 HEX 색상 코드를 RGB 색상 코드로 변환해줍니다. 색상 값 간 변환이 필요할 때 사용하세요.</p>
    </div>
  );
};

export default ColorConverter;
