import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

// 숫자에 쉼표를 추가하는 함수
const formatNumberWithComma = (number) => {
  if (!number) return '';
  return parseFloat(number).toLocaleString(); // 세 자리마다 쉼표 추가
};

const StockReturnCalculator = () => {
  const [buyPrice, setBuyPrice] = useState('');
  const [sellPrice, setSellPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [returnAmount, setReturnAmount] = useState(null);
  const [returnRate, setReturnRate] = useState(null);

  const calculateReturn = (e) => {
    e.preventDefault();
    const bp = parseFloat(buyPrice);
    const sp = parseFloat(sellPrice);
    const qty = parseFloat(quantity);

    if (bp > 0 && sp >= 0 && qty > 0) {
      const profit = (sp - bp) * qty;
      const rate = ((sp - bp) / bp) * 100;

      setReturnAmount(formatNumberWithComma(profit.toFixed(0)));
      setReturnRate(rate.toFixed(2));
    } else {
      setReturnAmount(null);
      setReturnRate(null);
    }
  };

  const resetCalculator = () => {
    setBuyPrice('');
    setSellPrice('');
    setQuantity('');
    setReturnAmount(null);
    setReturnRate(null);
  };

  return (
    <div className="content-box stock-return-calculator">
      <Helmet>
        <title>주식 수익률 계산기</title>
        <meta name="description" content="주식 수익률을 계산할 수 있는 도구입니다." />
        <meta name="keywords" content="주식 수익률 계산기, 주식 투자, 수익 계산, 금융 도구, 주식 수익, 투자 수익률, 주식 이익 계산, 투자 수익 계산기, 주식 포트폴리오 관리, 투자 분석, 주식 가격 추적, 주식 거래 수익, 주식 수익률 분석, 주식 배당 수익, 주식 평가, 투자 관리, 주식 수익 계산 도구, 투자 포트폴리오, 금융 투자 분석, 주식 수익 비교" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>주식 수익률 계산기</h2>
      <form onSubmit={calculateReturn} className="stock-form">
        <div className="input-group">
          <label>매수 가격 (원)</label>
          <input
            type="number"
            value={buyPrice}
            onChange={(e) => setBuyPrice(e.target.value)}
            placeholder="예: 50,000"
            required
          />
        </div>
        <div className="input-group">
          <label>매도 가격 (원)</label>
          <input
            type="number"
            value={sellPrice}
            onChange={(e) => setSellPrice(e.target.value)}
            placeholder="예: 55,000"
            required
          />
        </div>
        <div className="input-group">
          <label>수량 (주)</label>
          <input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            placeholder="예: 100"
            required
          />
        </div>
        <button type="submit">계산하기</button>
        <button type="button" onClick={resetCalculator} className="reset-button">
          초기화
        </button>
      </form>
      {returnAmount && (
        <div className="result">
          <h3>수익 금액: {returnAmount} 원</h3>
          <h3>수익률: {returnRate} %</h3>
        </div>
      )}
      <p className="description">
        주식 수익률 계산기는 매수 가격, 매도 가격, 그리고 수량을 입력하여 주식 투자로 얻은 수익을 계산할 수 있는 도구입니다. <br /><br />
        - **수익 금액**: 매도 가격과 매수 가격의 차이에 수량을 곱한 값으로, 주식 거래로 얻은 실제 금액입니다. <br />
        - **수익률**: 매수 가격과 매도 가격의 차이를 매수 가격으로 나눈 값으로, 투자 수익률을 퍼센트(%)로 나타냅니다. <br /><br />
        예를 들어, 매수 가격이 50,000원, 매도 가격이 55,000원, 수량이 100주일 때 수익 금액은 500,000원이고, 수익률은 10%입니다.
      </p>
    </div>
  );
};

export default StockReturnCalculator;
