import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

// 숫자에 쉼표를 추가하는 함수
const formatNumberWithComma = (number) => {
  if (!number) return '';
  return Math.round(number).toLocaleString(); // 세 자리마다 쉼표 추가, 소수점 제거
};

const RetirementCalculator = () => {
  const [currentAge, setCurrentAge] = useState('');
  const [retirementAge, setRetirementAge] = useState('');
  const [currentSavings, setCurrentSavings] = useState('');
  const [monthlyContribution, setMonthlyContribution] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [futureValue, setFutureValue] = useState(null);

  // 퇴직 연금 계산 함수
  const calculateRetirementSavings = (e) => {
    e.preventDefault();

    const n = (parseInt(retirementAge) - parseInt(currentAge)) * 12; // 총 기간 (월)
    const r = parseFloat(interestRate) / 100 / 12; // 월간 이자율
    const P = parseFloat(currentSavings.replace(/,/g, '')); // 쉼표 제거 후 현재 저축 금액
    const PMT = parseFloat(monthlyContribution.replace(/,/g, '')); // 쉼표 제거 후 월 적립 금액

    let futureSavings;

    // 이자율이 0인 경우
    if (r === 0) {
      futureSavings = P + PMT * n;
    } else {
      futureSavings = P * Math.pow(1 + r, n) + PMT * ((Math.pow(1 + r, n) - 1) / r);
    }

    setFutureValue(formatNumberWithComma(futureSavings)); // 쉼표 추가하여 결과 표시
  };

  // 계산기 초기화
  const resetCalculator = () => {
    setCurrentAge('');
    setRetirementAge('');
    setCurrentSavings('');
    setMonthlyContribution('');
    setInterestRate('');
    setFutureValue(null);
  };

  return (
    <div className="content-box retirement-calculator">
      <Helmet>
        <title>퇴직 연금 계산기</title>
        <meta name="description" content="퇴직 연금을 계산할 수 있는 도구입니다." />
        <meta name="keywords" content="퇴직 연금 계산기, 연금 계산, 퇴직 계획, 재정 관리, 은퇴 계획, 연금 상환, 연금 수령, 퇴직 자금 관리, 퇴직 자금 계산, 퇴직 후 생활, 재정 계획 도구, 연금 수익 계산기, 연금 관리 도구, 재정 추적, 퇴직 자금 추적, 은퇴 후 재정 관리, 연금 지급 계산기, 연금 비율 계산, 연금 수익률, 재정 안정" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>퇴직 연금 계산기</h2>
      <form onSubmit={calculateRetirementSavings} className="retirement-form">
        <div className="input-group">
          <label>현재 나이</label>
          <input
            type="number"
            value={currentAge}
            onChange={(e) => setCurrentAge(e.target.value)}
            placeholder="예: 30"
            required
          />
        </div>
        <div className="input-group">
          <label>퇴직 예정 나이</label>
          <input
            type="number"
            value={retirementAge}
            onChange={(e) => setRetirementAge(e.target.value)}
            placeholder="예: 65"
            required
          />
        </div>
        <div className="input-group">
          <label>현재 저축 금액 (원)</label>
          <input
            type="text"
            value={formatNumberWithComma(currentSavings)}  // 쉼표 형식으로 표시
            onChange={(e) => setCurrentSavings(e.target.value.replace(/[^0-9]/g, ''))}  // 숫자만 입력되도록
            placeholder="예: 10,000,000"
            required
          />
        </div>
        <div className="input-group">
          <label>월 저축 금액 (원)</label>
          <input
            type="text"
            value={formatNumberWithComma(monthlyContribution)}  // 쉼표 형식으로 표시
            onChange={(e) => setMonthlyContribution(e.target.value.replace(/[^0-9]/g, ''))}  // 숫자만 입력되도록
            placeholder="예: 500,000"
            required
          />
        </div>
        <div className="input-group">
          <label>연 이자율 (%)</label>
          <input
            type="number"
            step="0.01"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            placeholder="예: 5"
            required
          />
        </div>
        <button type="submit">계산하기</button>
        <button type="button" onClick={resetCalculator} className="reset-button">
          초기화
        </button>
      </form>
      {futureValue && (
        <div className="result">
          <h3>퇴직 시 예상 자산: {futureValue} 원</h3>
        </div>
      )}
      <p className="description">
        이 퇴직 연금 계산기는 현재 나이, 퇴직 예정 나이, 현재 저축 금액, 매월 저축 금액,
        연 이자율을 기반으로 퇴직 시점에서 예상되는 총 자산을 계산해줍니다.<br /><br />

        - 현재 저축 금액: 이미 저축한 금액.<br />
        - 월 저축 금액: 매월 저축할 금액.<br />
        - 연 이자율: 매년 예상되는 이자율.<br /><br />

        이 계산기는 복리로 증가하는 자산의 미래 가치를 예측하며, 적립하는 금액과 투자 기간이 길수록 더 큰 자산을 형성할 수 있습니다.
      </p>
    </div>
  );
};

export default RetirementCalculator;
