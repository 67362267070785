// LottoGenerator.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const LottoGenerator = () => {
  const [lottoNumbers, setLottoNumbers] = useState([]);

  const generateLottoNumbers = () => {
    const numbers = [];
    while (numbers.length < 6) {
      const num = Math.floor(Math.random() * 45) + 1;
      if (!numbers.includes(num)) {
        numbers.push(num);
      }
    }
    setLottoNumbers(numbers.sort((a, b) => a - b));
  };

  return (
    <div className="content-box lotto-generator">
      <Helmet>
        <title>로또 번호 추천</title>
        <meta name="description" content="랜덤 로또 번호를 생성해주는 도구입니다." />
        <meta name="keywords" content="로또 번호 생성기, 로또 추천, 로또 번호, 랜덤 번호 생성기, 로또 전략, 로또 분석, 당첨 번호 생성기, 복권 번호, 로또 시스템, 로또 확률 계산, 로또 게임, 랜덤 숫자, 로또 통계, 복권 전략, 복권 확률 계산, 복권 번호 예측, 로또 패턴 분석, 로또 당첨 가능성, 로또 추천 번호, 로또 자동 번호" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h1 className="page-title">로또 번호 추천</h1>
      <button onClick={generateLottoNumbers}>번호 생성</button>

      <div className="lotto-numbers">
        {lottoNumbers.map((number, index) => (
          <div key={index} className="lotto-number">
            {number}
          </div>
        ))}
      </div>

      <p className="description">
        이 도구는 무작위로 로또 번호를 추천해줍니다. 번호는 1부터 45 사이에서 선택됩니다.
      </p>
    </div>
  );
};

export default LottoGenerator;
