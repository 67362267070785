import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

// 숫자에 세 자리마다 쉼표를 추가하는 함수
const formatNumberWithComma = (number) => {
  if (!number) return '';
  return Math.round(number).toLocaleString(); // 세 자리마다 쉼표 추가, 소수점 제거
};

const InstallmentCalculator = () => {
  const [totalAmount, setTotalAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [installmentPeriod, setInstallmentPeriod] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState(null);

  // 할부 계산 함수
  const calculateInstallment = (e) => {
    e.preventDefault();

    const principal = parseFloat(totalAmount.replace(/,/g, '')) || 0; // 쉼표를 제거하고 숫자로 변환, 없으면 0 처리
    const rate = parseFloat(interestRate) / 100 || 0;  // 이자율 없으면 0으로 처리
    const period = parseInt(installmentPeriod) || 0; // 기간 없으면 0으로 처리

    if (principal > 0 && period > 0) {
      let monthlyPayment;

      // 이자율이 0일 경우, 무이자 할부 계산
      if (rate === 0) {
        monthlyPayment = principal / period;
      } else {
        const monthlyInterest = rate / 12;
        monthlyPayment = (principal * monthlyInterest) / (1 - Math.pow(1 + monthlyInterest, -period));
      }

      setMonthlyPayment(formatNumberWithComma(Math.round(monthlyPayment))); // 반올림 및 쉼표 추가
    } else {
      setMonthlyPayment(null);
    }
  };

  const resetCalculator = () => {
    setTotalAmount('');
    setInterestRate('');
    setInstallmentPeriod('');
    setMonthlyPayment(null);
  };

  return (
    <div className="content-box installment-calculator">
      <Helmet>
        <title>할부 이자 계산기</title>
        <meta name="description" content="할부 이자를 계산할 수 있는 도구입니다." />
        <meta name="keywords" content="할부 이자 계산기, 할부 계산, 이자 계산, 금융 계산기, 월 할부 계산, 금리 계산, 할부 비용 계산기, 할부 기간, 할부 금액, 금융 도구, 대출 할부, 할부 상환, 금융 계획, 월별 할부 계산기, 이자율 계산 도구, 이자 계산 공식, 할부 계획, 재정 관리, 할부 이익 계산기, 할부 상품 분석" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>할부 이자 계산기</h2>
      <form onSubmit={calculateInstallment} className="installment-form">
        <div className="input-group">
          <label>총 금액 (원)</label>
          <input
            type="text"
            value={formatNumberWithComma(totalAmount)} // 쉼표가 포함된 값 표시
            onChange={(e) => setTotalAmount(e.target.value.replace(/[^0-9]/g, ''))} // 숫자 외 문자는 입력되지 않도록 처리
            placeholder="예: 1,000,000"
            required
          />
        </div>
        <div className="input-group">
          <label>연 이자율 (%)</label>
          <input
            type="number"
            step="0.01"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            placeholder="예: 5"
            required
          />
        </div>
        <div className="input-group">
          <label>할부 기간 (개월)</label>
          <input
            type="number"
            value={installmentPeriod}
            onChange={(e) => setInstallmentPeriod(e.target.value)}
            placeholder="예: 12"
            required
          />
        </div>
        <button type="submit">계산하기</button>
        <button type="button" onClick={resetCalculator} className="reset-button">
          초기화
        </button>
      </form>
      {monthlyPayment && (
        <div className="result">
          <h3>월 할부금: {monthlyPayment} 원</h3>
        </div>
      )}
      <p className="description">
        할부 이자 계산기는 총 금액, 연 이자율, 할부 기간을 입력하여 매달 지불해야 하는 할부 금액을 계산합니다. 이 도구를 통해 매달의 지출을 예측하고 재정을 계획할 수 있습니다.
      </p>
    </div>
  );
};

export default InstallmentCalculator;
