import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// 금액에 쉼표를 추가하는 함수
const formatNumberWithComma = (number) => {
  if (!number) return '';
  return Math.round(number).toLocaleString(); // 세 자리마다 쉼표 추가, 소수점 제거 및 반올림
};

const ExpenseTracker = () => {
  const [expenses, setExpenses] = useState(() => {
    const storedExpenses = localStorage.getItem('expenses');
    return storedExpenses ? JSON.parse(storedExpenses) : [];
  });
  const [expenseTitle, setExpenseTitle] = useState('');
  const [expenseAmount, setExpenseAmount] = useState('');
  const [totalExpense, setTotalExpense] = useState(() => {
    const storedTotalExpense = localStorage.getItem('totalExpense');
    return storedTotalExpense ? parseFloat(storedTotalExpense) : 0;
  });
  const [categoryFilter, setCategoryFilter] = useState('all');
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const storedMonth = localStorage.getItem('selectedMonth');
    if (storedMonth) {
      return storedMonth;
    }
    const currentMonth = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}`;
    return currentMonth;
  });

  const [categories] = useState([
    '식비', '교통비', '주거비', '엔터테인먼트', '쇼핑', '교육', '건강', '기타'
  ]);

  // 지출 항목과 총 지출을 로컬 스토리지에 저장
  useEffect(() => {
    localStorage.setItem('expenses', JSON.stringify(expenses));
    localStorage.setItem('totalExpense', totalExpense);
    localStorage.setItem('selectedMonth', selectedMonth);
  }, [expenses, totalExpense, selectedMonth]);

  // 지출 항목 추가 함수
  const addExpense = (e) => {
    e.preventDefault();

    const amount = parseFloat(expenseAmount.replace(/,/g, '')); // 쉼표 제거 후 숫자로 변환
    if (expenseTitle && amount > 0) {
      const newExpense = {
        title: expenseTitle,
        amount: amount,
        category: categoryFilter,
        date: new Date().toLocaleDateString(),
        month: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}`
      };

      setExpenses([...expenses, newExpense]);
      setTotalExpense(totalExpense + amount);
      setExpenseTitle('');
      setExpenseAmount('');
    }
  };

  // 지출 항목 삭제 함수
  const removeExpense = (index) => {
    const newExpenses = [...expenses];
    const removedAmount = newExpenses[index].amount;
    newExpenses.splice(index, 1);
    setExpenses(newExpenses);
    setTotalExpense(totalExpense - removedAmount);
  };

  // 저장된 지출 내역에서 월 추출 후 중복 제거
  const getUniqueMonthsFromExpenses = () => {
    const uniqueMonths = [...new Set(expenses.map(expense => expense.month))];
    return uniqueMonths.sort((a, b) => new Date(b) - new Date(a)); // 최신 순 정렬
  };

  // 선택된 월에 맞는 지출 내역 필터링
  const filterExpensesByMonth = () => {
    return expenses.filter(expense => expense.month === selectedMonth);
  };

  // 카테고리별 지출 필터링
  const filterExpensesByCategory = (category) => {
    const filteredByMonth = filterExpensesByMonth();
    if (category === 'all') {
      return filteredByMonth;
    }
    return filteredByMonth.filter((expense) => expense.category === category);
  };

  // 총 금액 함수
  const calculateTotalByCategory = (category) => {
    const filteredExpenses = filterExpensesByCategory(category);
    return filteredExpenses.reduce((sum, expense) => sum + expense.amount, 0);
  };

  return (
    <div className="content-box expense-manager">
      <Helmet>
        <title>지출 관리기</title>
        <meta name="description" content="지출을 관리할 수 있는 도구입니다." />
        <meta name="keywords" content="지출 관리기, 지출 관리, 가계부, 예산 관리, 재정 관리, 예산 계획, 돈 관리, 재정 분석, 비용 절감, 지출 통제, 월별 지출 계산기, 가계부 작성, 재정 계획 도구, 소비 기록, 예산 추적, 지출 절약, 재정 추적 도구, 지출 통계, 예산 계획 도구, 재정 통계" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>지출 관리기</h2>

      <form onSubmit={addExpense} className="expense-form">
        <div className="input-group">
          <label>지출 내역</label>
          <input
            type="text"
            value={expenseTitle}
            onChange={(e) => setExpenseTitle(e.target.value)}
            placeholder="지출 항목 입력"
            required
          />
        </div>
        <div className="input-group">
          <label>금액 (원)</label>
          <input
            type="text"
            value={formatNumberWithComma(expenseAmount)}
            onChange={(e) => setExpenseAmount(e.target.value.replace(/[^0-9]/g, ''))}
            placeholder="예: 50,000"
            required
          />
        </div>
        <div className="input-group">
          <label>카테고리</label>
          <select value={categoryFilter} onChange={(e) => setCategoryFilter(e.target.value)}>
            <option value="all">전체</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">추가하기</button>
      </form>

      {/* 로컬스토리지에 존재하는 월만 보여주기 */}
      <div className="input-group">
        <label>월 선택</label>
        <select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
          {getUniqueMonthsFromExpenses().map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </select>
      </div>

      <div className="expense-list">
        <h3>지출 내역</h3>
        {filterExpensesByMonth().length === 0 ? (
          <p>선택된 월에 지출 항목이 없습니다.</p>
        ) : (
          <ul>
            {filterExpensesByCategory(categoryFilter).map((expense, index) => (
              <li key={index}>
                <span>{expense.date} - {expense.title}: {formatNumberWithComma(expense.amount)} 원 ({expense.category})</span>
                <button onClick={() => removeExpense(index)} className="delete-button">삭제</button>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="summary">
        <h3>총 지출: {formatNumberWithComma(totalExpense)} 원</h3>
        <p>카테고리별 지출:</p>
        <ul>
          {categories.map((category) => (
            <li key={category}>
              {category}: {formatNumberWithComma(calculateTotalByCategory(category))} 원
            </li>
          ))}
        </ul>
      </div>

      <p className="description">
        이 지출 관리기는 매일의 지출을 기록하고 관리할 수 있는 도구입니다. 항목을 추가하고, 삭제하고, 카테고리 및 월별로 지출을 확인할 수 있습니다. <br /><br />
        주의: 이 도구는 데이터를 로컬스토리지에 저장합니다. 하지만 브라우저의 쿠키 및 사이트 데이터를 삭제하거나 브라우저 설정에 따라 자동으로 데이터를 정리하는 경우, 저장된 내역이 사라질 수 있습니다. 데이터를 안전하게 보관하려면 브라우저 설정을 확인해주세요.
      </p>
    </div>
  );
};

export default ExpenseTracker;
