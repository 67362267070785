import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

// 성적과 학점 정보를 관리하는 학점 계산기
const GPACalculator = () => {
  // 상태 변수 정의
  const [courses, setCourses] = useState([{ grade: '', credit: '' }]);
  const [gpa, setGpa] = useState(null);

  // 과목 추가
  const addCourse = () => {
    setCourses([...courses, { grade: '', credit: '' }]);
  };

  // 과목 삭제
  const removeCourse = (index) => {
    const newCourses = courses.filter((_, i) => i !== index);
    setCourses(newCourses);
  };

  // 과목 정보 변경
  const handleCourseChange = (index, field, value) => {
    const newCourses = [...courses];
    newCourses[index][field] = value;
    setCourses(newCourses);
  };

  // GPA 계산
  const calculateGPA = (e) => {
    e.preventDefault();

    let totalPoints = 0;
    let totalCredits = 0;
    let hasInvalidGrade = false;

    courses.forEach((course) => {
      const grade = course.grade.toUpperCase();
      const credit = parseFloat(course.credit);

      let gradePoint = 0;

      switch (grade) {
        case 'A+':
          gradePoint = 4.5;
          break;
        case 'A':
          gradePoint = 4.0;
          break;
        case 'B+':
          gradePoint = 3.5;
          break;
        case 'B':
          gradePoint = 3.0;
          break;
        case 'C+':
          gradePoint = 2.5;
          break;
        case 'C':
          gradePoint = 2.0;
          break;
        case 'D+':
          gradePoint = 1.5;
          break;
        case 'D':
          gradePoint = 1.0;
          break;
        case 'F':
          gradePoint = 0.0;
          break;
        default:
          hasInvalidGrade = true;
          gradePoint = 0.0;
      }

      if (!isNaN(credit)) {
        totalPoints += gradePoint * credit;
        totalCredits += credit;
      }
    });

    if (hasInvalidGrade) {
      alert("유효하지 않은 성적이 포함되어 있습니다.");
    } else if (totalCredits > 0) {
      const calculatedGpa = totalPoints / totalCredits;
      setGpa(calculatedGpa.toFixed(2));
    } else {
      setGpa(null);
    }
  };

  // 계산기 초기화
  const resetCalculator = () => {
    setCourses([{ grade: '', credit: '' }]);
    setGpa(null);
  };

  return (
    <div className="content-box gpa-calculator">
      <Helmet>
        <title>학점 계산기</title>
        <meta name="description" content="학점을 계산할 수 있는 도구입니다." />
        <meta name="keywords" content="학점 계산기, GPA 계산기, 학점 관리, 대학 학점, 성적 계산, 학업 관리, 성적 추적, 학점 평균, GPA 관리, 학점 계산 도구, 학업 성취, 학점 통계, 성적 분석, 학점 향상 도구, 성적 관리 도구, 대학 GPA 계산, 학점 평가, 성적 기록, GPA 계산 방법, 학업 성과 분석" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>학점 계산기</h2>
      <form onSubmit={calculateGPA} className="gpa-form">
        {courses.map((course, index) => (
          <div key={index} className="course-input">
            <div className="input-group">
              <label>성적</label>
              <input
                type="text"
                value={course.grade}
                onChange={(e) => handleCourseChange(index, 'grade', e.target.value)}
                placeholder="성적"
                required
              />
            </div>
            <div className="input-group">
              <label>학점</label>
              <input
                type="number"
                step="0.5"
                value={course.credit}
                onChange={(e) => handleCourseChange(index, 'credit', e.target.value)}
                placeholder="학점"
                required
              />
            </div>
            <button type="button" onClick={() => removeCourse(index)} className="remove-button">
              삭제
            </button>
          </div>
        ))}
        <button type="button" onClick={addCourse} className="add-button">
          과목 추가
        </button>
        <button type="submit">계산하기</button>
        <button type="button" onClick={resetCalculator} className="reset-button">
          초기화
        </button>
      </form>
      {gpa && (
        <div className="result">
          <h3>평균 평점 (GPA): {gpa}</h3>
        </div>
      )}
      <p className="description">
        이 학점 계산기는 과목별 성적과 학점을 입력하여 평균 평점을 계산합니다. <br /><br />
        성적 예시: A+, A, B+ 등 <br />
        성적에 따른 평점은 다음과 같습니다: <br />
        A+ = 4.5, A = 4.0, B+ = 3.5, B = 3.0, C+ = 2.5, C = 2.0, D+ = 1.5, D = 1.0, F = 0.0 <br /><br />
        과목 추가 및 삭제 기능을 통해 여러 과목의 성적을 입력하고, 해당 학기의 GPA를 쉽게 계산할 수 있습니다.
      </p>
    </div>
  );
};

export default GPACalculator;
