// ReactionTimeTest.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const ReactionTimeTest = () => {
  const [start, setStart] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [reactionTime, setReactionTime] = useState(null);
  const [waiting, setWaiting] = useState(false);

  const handleStart = () => {
    setStart(false);
    setReactionTime(null);
    setWaiting(true);

    // 랜덤 시간 후에 버튼을 표시
    const delay = Math.floor(Math.random() * 5000) + 1000;
    setTimeout(() => {
      setStartTime(Date.now());
      setStart(true);
      setWaiting(false);
    }, delay);
  };

  const handleClick = () => {
    if (start) {
      const endTime = Date.now();
      const time = endTime - startTime;
      setReactionTime(time);
      setStart(false);
    }
  };

  return (
    <div className="content-box reaction-test">
      <Helmet>
        <title>반응 속도 테스트</title>
        <meta name="description" content="반응 속도를 측정할 수 있는 도구입니다." />
        <meta name="keywords" content="반응 속도 테스트, 반응 시간, 속도 측정, 게임, 반응 테스트, 속도 훈련, 반응 능력, 반응력 평가, 반응 훈련, 반응속도 게임, 스피드 테스트, 집중력 테스트, 반응 시간 훈련, 반응력 향상, 속도 경쟁, 반응 분석, 반사 신경 테스트, 반사 신경 훈련, 두뇌 훈련 게임, 속도 측정 도구" />
        <meta property="og:image" content="https://www.smarttools365.com/favicon.ico" />
      </Helmet>
      <h2>반응 속도 테스트</h2>
      <p>{waiting ? '잠시만 기다려 주세요...' : '버튼이 나타나면 빠르게 클릭하세요!'}</p>
      {start ? (
        <button onClick={handleClick} style={{ padding: '20px 40px', fontSize: '20px' }}>
          클릭하세요!
        </button>
      ) : (
        <button onClick={handleStart} style={{ padding: '20px 40px', fontSize: '20px' }}>
          테스트 시작
        </button>
      )}
      {reactionTime && <p>당신의 반응 속도: {reactionTime}ms</p>}
      <p className="description">
          이 반응 속도 테스트 게임은 화면이 변하는 순간을 기다렸다가 클릭하여 자신의 반응 속도를 측정하는 게임입니다. 평균 반응 속도를 측정하여 얼마나 빠르게 반응하는지 확인해보세요.
      </p>
    </div>
  );
};

export default ReactionTimeTest;
